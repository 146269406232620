import * as React from "react";
import { SVGProps } from "react";

export const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20px"
    height="18px"
    viewBox="0 0 20 18"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <title>{"86F812A1-A07F-48CC-984C-84C4FF17E22A"}</title>
    <g
      id="Desktop"
      stroke="none"
      strokeWidth={1}
      fill="#ffffff"
      fillRule="evenodd"
    >
      <g
        id="Converter"
        transform="translate(-830, -741)"
        stroke="#ffffff"
        strokeWidth={1.875}
      >
        <g
          id="System-Icons/Sort-by"
          transform="translate(840.0789, 750.0008) rotate(-270) translate(-840.0789, -750.0008)translate(831.9796, 741.7112)"
        >
          <g id="Group-9" transform="translate(-0, -0)">
            <line
              x1={12.3918012}
              y1={16.5791316}
              x2={12.3918012}
              y2={0.000184210527}
              id="Stroke-1"
            />
            <line
              x1={3.80652632}
              y1={0.000184210527}
              x2={3.80652632}
              y2={16.5791316}
              id="Stroke-3"
            />
            <polyline
              id="Stroke-5"
              points="0 3.80671053 3.80671053 -1.2195672e-13 7.61342105 3.80671053"
            />
            <polyline
              id="Stroke-7"
              points="8.58527485 12.7726053 12.3919854 16.5793158 16.1986959 12.7726053"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default SvgComponent;

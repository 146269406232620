import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 52 52" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15.818 3.545a5.28 4.377 0 0 0-.222.013 5.28 4.377 0 0 0-5.06 4.368 5.28 4.377 0 0 0 5.06 4.36v.054h17.591L4.381 41.147l.02.02a4.384 5.494 45 0 0-.685 6.88 4.384 5.494 45 0 0 6.981-.786 4.384 5.494 45 0 0 .242-.255l29.344-29.344v18.895a4.377 5.28 0 0 0-.013.249 4.377 5.28 0 0 0 .013.222 4.377 5.28 0 0 0 4.361 5.06 4.377 5.28 0 0 0 4.368-5.06h.054V3.558H16.194a5.28 4.377 0 0 0-.376-.013z"
      style={{
        fill: "#008171",
        fillOpacity: 1,
        strokeWidth: 3.53036,
      }}
    />
  </svg>
);

export default SvgComponent;

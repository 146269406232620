import { graphql, useStaticQuery } from "gatsby";
import { IGenericData, IRawNode } from "@app/models/storyblok/datasource";

export const useGenericData = (): IGenericData | undefined => {
  const { genericData } = useStaticQuery(graphql`
    {
      genericData: allStoryblokDatasourceEntry(
        filter: { data_source: { eq: "generic" } }
      ) {
        edges {
          node {
            value
            name
          }
        }
      }
    }
  `);

  try {
    return genericData.edges.reduce(
      (data: IGenericData, property: IRawNode) => ({
        ...data,
        [property.node.name]: JSON.parse(JSON.parse(property.node.value)),
      }),
      {},
    );
  } catch (e) {
    console.log("Generic data parsing error!");

    return undefined;
  }
};

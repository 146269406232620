/**
 * Filter storyblok collection by language
 * @param language The language to filter
 * @param items The edges received from GraphQL
 * @returns A map of with the collection items in the specified language
 */
export const filterStoryblokCollectionByLanguage = <T>(
  language: string,
  items: { node: { uuid: string; lang: string } }[],
): { [id: string]: T } => {
  const result = items
    .filter((c) => {
      const lang = c.node.lang === "default" ? "en" : c.node.lang;

      return lang === language;
    })
    .reduce((prev, entry) => {
      prev[entry.node.uuid] = entry.node;

      return prev;
    }, {});

  return result;
};

export const INIT_FORM_STATE = {
  isDataLoading: true,
  isCalendarDisabled: false,
};

export const FORM_IDS = {
  en: "f96d4308-96a3-4ac9-9698-99905a044bbc",
  de: "9f90dda8-b708-47e5-a70b-4d8194ef5775",
  fr: "4142682e-90ca-4eff-ae1b-80e8ec1a5979",
  it: "30d79ce6-3960-4650-adc3-f7236296d9c7",
};

export const RECAPTCHA_BUSINESSES_ACTION = "businesses";
export const RECAPTCHA_STAFF_ACTION = "staff_availability";
export const RECAPTCHA_APPOINTMENT_ACTION = "book_appointment";

export const DEFAULT_LANGUAGE = "en";

import React from "react";
import { useTranslation } from "react-i18next";
import { useI18next } from "gatsby-plugin-react-i18next";
import classNames from "classnames";
import { ButtonVariant } from "@app/components/common/enums";
import { Button } from "@app/components/common";
import { ModalState } from "@app/enums/modal";
import { useModal, useIsMDMediaQuery, useIsXLMediaQuery } from "@app/hooks";
import { AccordionMenu } from "./components/accordion-menu/AccordionMenu";
import { MenuTitle } from "./components/menu-title/MenuTitle";
import { Menu } from "./components/menu/Menu";

const NUMBER_OF_COLUMNS_MD = 3;

export const Navigation: React.FC = () => {
  const { t } = useTranslation("footer");
  const { language, defaultLanguage } = useI18next();
  const { onChangeModalState } = useModal();
  const isMDScreen = useIsMDMediaQuery();
  const isXLScreen = useIsXLMediaQuery();

  const menus = [
    {
      title: t("menu_1_title"),
      items: [
        { label: t("banking"), link: "/bank" },
        { label: t("investing"), link: "/investment" },
        { label: t("wealth_management"), link: "/wealth-management" },
        { label: t("applepay"), link: "/bank/apple-pay" },
        { label: t("pricing"), link: "/pricing" },
      ],
    },
    {
      title: t("menu_2_title"),
      items: [
        {
          label: t("blog"),
          link: `https://www.blog.alpian.com${
            language === defaultLanguage ? "" : `/${language}`
          }`,
        },
        { label: t("refer_a_friend"), link: "/refer-a-friend" },
        { label: t("fees_simulator"), link: "/investment/cost-calculator" },
        { label: t("brandbook"), link: "https://brandbook.alpian.com" },
        { label: t("philosophy"), link: "/investment/our-philosophy" },
        { label: t("currency_converter"), link: "/bank/currency-converter/" },
        { label: t("film"), link: "https://brandbook.alpian.com/films" },
      ],
    },
    {
      title: t("menu_3_title"),
      items: [
        { label: t("about_us"), link: "/about-us" },
        { label: t("events"), link: "/events" },
        { label: t("news"), link: "/news" },
        { label: t("ambassadors"), link: "/alpian-ambassadors" },
        { label: t("careers"), link: "/careers" },
        { label: t("financialreport"), link: "/financial-reports" },
      ],
    },
    {
      title: t("menu_4_title"),
      items: [
        { label: t("lost_cards"), link: "/lost-card" },
        { label: t("contact"), link: "/contact" },
        { label: t("faq"), link: "/faq" },
      ],
    },
  ];

  return (
    <div
      className={classNames(
        "mb-10 grid grid-flow-row border-b border-solid border-divider pb-10",
        "md:mb-7.5 md:grid-flow-row-dense md:grid-cols-3 md:gap-x-15 md:pb-0",

        "lg:gap-x-25",
        "xl:grid-cols-6 xl:gap-x-11",
      )}
    >
      {!isMDScreen &&
        menus.map((menu) => (
          <AccordionMenu
            key={menu.title}
            title={menu.title}
            items={menu.items}
          />
        ))}
      {isMDScreen &&
        !isXLScreen &&
        menus
          .filter((menu, i) => i < NUMBER_OF_COLUMNS_MD)
          .map((menu) => <MenuTitle key={menu.title} title={menu.title} />)}
      {isMDScreen &&
        !isXLScreen &&
        menus
          .filter((menu, i) => i < NUMBER_OF_COLUMNS_MD)
          .map((menu) => <Menu key={menu.title} items={menu.items} />)}
      {isMDScreen &&
        !isXLScreen &&
        menus
          .filter((menu, i) => i >= NUMBER_OF_COLUMNS_MD)
          .map((menu) => <MenuTitle key={menu.title} title={menu.title} />)}
      {isMDScreen &&
        !isXLScreen &&
        menus
          .filter((menu, i) => i >= NUMBER_OF_COLUMNS_MD)
          .map((menu) => <Menu key={menu.title} items={menu.items} />)}
      {isXLScreen &&
        menus.map((menu) => <MenuTitle key={menu.title} title={menu.title} />)}
      {isXLScreen &&
        menus.map((menu) => <Menu key={menu.title} items={menu.items} />)}
      <h4
        className={classNames(
          "mt-10 pb-5 text-medium-low text-primary-content",
          "md:col-span-2 md:col-start-2 md:row-start-3 md:mt-0 md:pb-6 md:text-medium",
          "xl:col-start-5 xl:row-start-1",
        )}
      >
        {t("subscribe_title")}
      </h4>
      <div
        className={classNames(
          "md:col-span-2 md:col-start-2 md:pb-6",
          "xl:col-start-5 xl:row-start-2",
        )}
      >
        <Button
          label={t("subscribe_link")}
          variant={ButtonVariant.OUTLINE}
          isFullWidth={true}
          onClick={() => onChangeModalState(ModalState.SUBSCRIBE)}
        />
      </div>
    </div>
  );
};

import React from "react";
import { useTranslation } from "react-i18next";

type Props = {
  necessary: string;
  nonNecessary: string;
  isNotNecessaryEnabled: boolean;
  toggleNotNecessary: (value: boolean) => void;
};

export const CookiesUsage: React.FC<Props> = ({
  necessary,
  nonNecessary,
  isNotNecessaryEnabled,
  toggleNotNecessary,
}) => {
  const { t } = useTranslation("cookiesettings_modal");

  return (
    <div className="mt-8">
      <div className="collapse rounded-none bg-transparent">
        <input
          type="checkbox"
          className="peer"
          aria-label="Neccesary cookies"
        />
        <div className="text-xl group collapse-title flex flex-row items-center justify-between rounded-md border-none bg-[#1b1c1d] p-4 font-medium text-grey-neutral">
          <div className="flex flex-row items-center gap-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={3}
              stroke="currentColor"
              className="h-4 w-4 text-white"
            >
              <path
                className="peer-checked:group-[]:hidden"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />

              <path
                className="hidden peer-checked:group-[]:block"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m19.5 8.25-7.5 7.5-7.5-7.5"
              />
            </svg>

            <div>{t("neccesary")}</div>
          </div>
          <div>{t("always_enabled")}</div>
        </div>

        <div className="collapse-content prose mt-5 max-w-full bg-transparent px-0 text-grey-neutral">
          {necessary}
        </div>
      </div>

      <div className="collapse rounded-none bg-transparent">
        <input type="checkbox" className="peer" aria-label="Optional cookies" />
        <div className="text-xl group collapse-title flex flex-row items-center justify-between rounded-md border-none bg-[#1b1c1d] p-4 font-medium text-grey-neutral">
          <div className="flex flex-row items-center gap-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={3}
              stroke="currentColor"
              className="h-4 w-4 text-white"
            >
              <path
                className="peer-checked:group-[]:hidden"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />

              <path
                className="hidden peer-checked:group-[]:block"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m19.5 8.25-7.5 7.5-7.5-7.5"
              />
            </svg>

            <div>{t("non_neccesary")}</div>
          </div>

          <div className="form-control relative z-10">
            <label className="label cursor-pointer gap-2">
              <span>
                {isNotNecessaryEnabled ? t("enabled") : t("disabled")}
              </span>
              <input
                type="checkbox"
                className="toggle toggle-lg border-none bg-white [--tglbg:#888888] checked:[--tglbg:#025c51] hover:bg-white"
                onChange={(event) => toggleNotNecessary(event.target.checked)}
                checked={isNotNecessaryEnabled}
              />
            </label>
          </div>
        </div>

        <div className="collapse-content prose mt-5 max-w-full bg-transparent px-0 text-grey-neutral">
          {nonNecessary}
        </div>
      </div>
    </div>
  );
};

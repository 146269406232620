import React, { FC, useRef } from "react";
import classNames from "classnames";
import { yupResolver } from "@hookform/resolvers/yup";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useTranslation, Trans } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import { Input, PhoneInput, Checkbox } from "@app/components/common/form";
import { Close } from "@app/components/common/icons";
import { Dialog } from "@app/constants/ui";
import { ModalState } from "@app/enums/modal";
import { GenericFormData } from "@app/types/forms";
import { useOutsideClick, useModal } from "@app/hooks";
import { Button } from "../../../button/Button";
import { RECAPTCHA_ACTION, FORM_IDS, UNDEFINED_VALUE } from "./constants";
import { useSubscribe } from "./useSubscribe";
import { useValidationSchema } from "./useValidationSchema";

export const Subscribe: FC = () => {
  const { t, i18n } = useTranslation("contact-form");
  const { onChangeModalState } = useModal();
  const { sendSubscribeRequest } = useSubscribe();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const ref = useRef<HTMLDivElement>(null);
  const validationSchema = useValidationSchema(t);
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      phoneCode: {
        alphaCode: "CH",
        code: "41",
      },
    },
  });

  const onClose = () => {
    onChangeModalState(ModalState.IDLE);
  };

  useOutsideClick(ref, onClose);

  const onSubmit = async (data: GenericFormData) => {
    try {
      const recaptchaToken = await executeRecaptcha(RECAPTCHA_ACTION);

      await sendSubscribeRequest(data, {
        action: RECAPTCHA_ACTION,
        token: recaptchaToken,
        location: window?.location?.href ?? UNDEFINED_VALUE,
        title: document?.title ?? UNDEFINED_VALUE,
        language: i18n.language,
        formId: FORM_IDS[i18n.language],
      });

      onChangeModalState(ModalState.SUBSCRIBE_SUCCESS);
    } catch (error) {
      onChangeModalState(ModalState.ERROR);
    }
  };

  return (
    <dialog id={Dialog.SUBSCIBE} className="modal modal-open z-[1000]">
      <div
        className={classNames(
          "modal-box relative max-h-screen w-screen rounded-none bg-dark px-5 pb-10 pt-15 text-primary-content",
          "scrollbar-thumb-rounded overflow-auto scrollbar scrollbar-thin scrollbar-track-transparent scrollbar-thumb-base-400",
          "md:max-h-[calc(100vh-2em)] md:rounded-lg md:px-10 md:pb-15",
          "xl:w-full xl:max-w-[600px] xl:px-20",
        )}
        ref={ref}
      >
        <h3
          className={classNames(
            "mb-5 text-large",
            "xl:mb-2.5 xl:text-center xl:text-large-tall",
          )}
        >
          {t("subscribe_title")}
        </h3>
        <p
          className={classNames(
            "mb-15 text-base-400",
            "xl:text-center xl:text-medium-standart",
          )}
        >
          {t("subscribe_subtitle")}
        </p>
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            className="w-full space-y-5 [&_a:hover]:text-primary-content [&_a:hover]:duration-300"
          >
            <Input name="firstName" label={`${t("first_name")} *`} />
            <Input name="lastName" label={`${t("last_name")} *`} />
            <Input name="email" label={`${t("email")} *`} type="email" />
            <PhoneInput
              phoneName="phone"
              codeName="phoneCode"
              label={`${t("phone")} *`}
              dropdownBgVariant="grey"
            />
            <Input
              name="howDidYouHear"
              label={`${t("how_did_you_hear_about_us")} *`}
            />
            <Checkbox
              name="isSwissResidentConfirmed"
              label={t("swiss_agree")}
            />
            <Checkbox
              name="isPrivacyPolicyConfirmed"
              label={
                <Trans
                  t={t}
                  i18nKey="agree"
                  components={[
                    <a
                      key="privacy-policy"
                      href="/legal/privacy-policy"
                      target="_blank"
                      rel="noopener noreferrer"
                    />,
                  ]}
                />
              }
            />
            <div className="flex justify-center">
              <Button
                label={t("form_submit_subscribe")}
                type="submit"
                isLoading={methods.formState.isSubmitting}
              />
            </div>
          </form>
        </FormProvider>
        <Close
          className={classNames(
            "absolute right-5 top-5 h-5 w-5 cursor-pointer duration-300",
            "lg:right-6 lg:top-6 lg:h-6 lg:w-6",
            "hover:opacity-75",
          )}
          onClick={onClose}
        />
      </div>
    </dialog>
  );
};

"use client";

import React, { createContext, FC, useState, useEffect } from "react";
import { useQueryParamString } from "react-use-query-param-string";
import { ModalState } from "@app/enums/modal";

type Modal = {
  modalState: ModalState;
  onChangeModalState: (state: ModalState) => void;
};

type Props = {
  children: React.ReactNode;
};

export const ModalContext = createContext({} as Modal);

export const ModalProvider: FC<Props> = ({ children }) => {
  const [modalUrlParam] = useQueryParamString("modal", "");
  const [modalState, setModalState] = useState(ModalState.IDLE);

  useEffect(() => {
    if (
      modalUrlParam &&
      modalUrlParam !== modalState &&
      Object.values(ModalState).includes(modalUrlParam as ModalState)
    ) {
      setModalState(modalUrlParam as ModalState);
    }
  }, [modalUrlParam]);

  const onChangeModalState = (state: ModalState) => setModalState(state);

  return (
    <ModalContext.Provider value={{ modalState, onChangeModalState }}>
      {children}
    </ModalContext.Provider>
  );
};

import React, { FC } from "react";
import { Link } from "@app/components/common/link/Link";
import { CustomTrackingEvent } from "@app/constants/tracking";
import { ModalState } from "@app/enums/modal";
import { OsNames } from "@app/constants";
import { useSiteData, useModal } from "@app/hooks";
import { ButtonVariant, LinkButtonType, StoryblokLinkType } from "../enums";
import { Button } from "./components/button/Button";
import { StoryblokInternalLink } from "./components/storyblok-internal-link/StoryblokInternalLink";
import { useOSName } from "./useOSName";

type Props = {
  label: string;
  to?: string;
  variant?: ButtonVariant;
  type?: LinkButtonType;
  fileRef?: string;
  fileName?: string;
  isFullWidth?: boolean;
  labelStyles?: string;
  storyblokType?: StoryblokLinkType;
  onClick?: () => void;
};

export const LinkButton: FC<Props> = ({
  label,
  to,
  variant,
  type,
  fileRef,
  fileName,
  isFullWidth,
  labelStyles,
  storyblokType,
}) => {
  const { androidLink, iosLink } = useSiteData();
  const { onChangeModalState } = useModal();
  const osName = useOSName();

  return (
    <>
      {type === LinkButtonType.DOWNLOAD_FILE ? (
        <a
          href={fileRef ?? to}
          download={fileName ?? null}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button
            label={label}
            variant={variant}
            isFullWidth={isFullWidth}
            labelStyles={labelStyles}
          />
        </a>
      ) : type === LinkButtonType.DOWNLOAD_APP ? (
        osName === OsNames.Android || osName === OsNames.IOs ? (
          <a
            href={osName === OsNames.Android ? androidLink : iosLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              label={label}
              variant={variant}
              isFullWidth={isFullWidth}
              labelStyles={labelStyles}
              trackEvent={CustomTrackingEvent.DOWNLOAD_APP}
            />
          </a>
        ) : (
          <Button
            label={label}
            variant={variant}
            isFullWidth={isFullWidth}
            labelStyles={labelStyles}
            trackEvent={CustomTrackingEvent.DOWNLOAD_APP}
            onClick={() => onChangeModalState(ModalState.DOWNLOAD_APP)}
          />
        )
      ) : type === LinkButtonType.SCHEDULE_A_CALL ? (
        <Button
          label={label}
          variant={variant}
          isFullWidth={isFullWidth}
          labelStyles={labelStyles}
          onClick={() => onChangeModalState(ModalState.SCHEDULE_A_CALL)}
        />
      ) : storyblokType === StoryblokLinkType.STORY ? (
        <StoryblokInternalLink id={to}>
          <Button
            label={label}
            variant={variant}
            isFullWidth={isFullWidth}
            labelStyles={labelStyles}
          />
        </StoryblokInternalLink>
      ) : (
        <Link to={to ?? ""}>
          <Button
            label={label}
            variant={variant}
            isFullWidth={isFullWidth}
            labelStyles={labelStyles}
          />
        </Link>
      )}
    </>
  );
};

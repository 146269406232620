import React, { FC } from "react";
import classNames from "classnames";
import { useFormContext } from "react-hook-form";

type Props = {
  name: string;
  label: string | React.ReactNode;
  isDisabled?: boolean;
};

export const Checkbox: FC<Props> = ({ name, label, isDisabled }) => {
  const { register, formState } = useFormContext();

  return (
    <div className="form-control w-full">
      <label
        className={classNames(
          "label w-full cursor-pointer items-start justify-start gap-x-2.5 p-0",
        )}
      >
        <input
          type="checkbox"
          className={classNames(
            "checkbox checkbox-sm translate-y-1 rounded-xs duration-300",
            "[&:disabled]:border-divider [&:disabled]:[--chkbg:theme(colors.divider)] [&:disabled]:checked:border-divider",
            "[&:disabled]:opacity-50 [&:disabled]:[--chkfg:theme(colors.primary-content)]",
            formState.errors[name]
              ? "checkbox-error"
              : "border-divider [--chkbg:theme(colors.divider)] [--chkfg:theme(colors.primary-content)] checked:border-divider",
          )}
          disabled={isDisabled || formState.isSubmitting}
          {...register(name)}
        />
        <span
          className={classNames(
            "label-text text-regular duration-300",
            { "opacity-50": isDisabled || formState.isSubmitting },
            formState.errors[name] ? "text-error" : "text-base-400",
          )}
        >
          {label}
        </span>
      </label>
    </div>
  );
};

import dayjs, { Dayjs } from "dayjs";
import duration from "dayjs/plugin/duration";
import utc from "dayjs/plugin/utc";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { AvailabilityItems, Service, TimeSlot } from "@app/models";
import { DEFAULT_TIME_INTERVAL } from "@app/constants";

dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(isSameOrBefore);

export const parseTimeIntervalToSeconds = (from: string) =>
  dayjs.duration(from).asSeconds();

export const parseTimeIntervalToMinutes = (from: string) =>
  dayjs.duration(from).asMinutes();

export const parseTimeIntervalToHours = (from: string) =>
  dayjs.duration(from).asHours();

export const getEndTime = (startTime: string | Dayjs, duration: string) =>
  dayjs(startTime).add(parseTimeIntervalToSeconds(duration), "second");

export const getEndOfMonth = (date: string) => dayjs(date).endOf("month");

export const getStartOfDay = (date: string | Date) =>
  dayjs(date).startOf("day");

export const getEndOfDay = (date: string) => dayjs(date).endOf("day");

export const getTimeslots = (
  source: AvailabilityItems[],
  service: Service,
): TimeSlot[] => {
  return source
    .map((employee) => {
      return {
        staffId: employee.staffId,
        slots: employee.availabilityItems.reduce((agg, current) => {
          const starDatetTime = service.preBuffer
            ? dayjs
                .utc(current.startDateTime.dateTime)
                .add(parseTimeIntervalToSeconds(service.preBuffer), "s")
            : dayjs.utc(current.startDateTime.dateTime);
          const endDateTime = service.postBuffer
            ? dayjs
                .utc(current.endDateTime.dateTime)
                .subtract(parseTimeIntervalToSeconds(service.postBuffer), "s")
                .subtract(
                  parseTimeIntervalToSeconds(
                    service.defaultDuration ?? DEFAULT_TIME_INTERVAL,
                  ),
                  "s",
                )
            : dayjs
                .utc(current.endDateTime.dateTime)
                .subtract(
                  parseTimeIntervalToSeconds(
                    service.defaultDuration ?? DEFAULT_TIME_INTERVAL,
                  ),
                  "s",
                );
          const dateSlots = [];
          const increment =
            parseTimeIntervalToSeconds(
              service.schedulingPolicy.timeSlotInterval,
            ) || parseTimeIntervalToSeconds(DEFAULT_TIME_INTERVAL);
          let slot = dayjs(starDatetTime);
          const startThresholdTime = service.schedulingPolicy?.minimumLeadTime
            ? dayjs().add(
                parseTimeIntervalToHours(
                  service.schedulingPolicy?.minimumLeadTime,
                ),
                "hours",
              )
            : dayjs();

          while (dayjs(slot).isSameOrBefore(endDateTime)) {
            if (slot.isAfter(startThresholdTime)) {
              dateSlots.push(slot);
            }
            slot = slot.add(increment, "s");
          }

          return [...agg, ...dateSlots];
        }, []),
      };
    })
    .reduce((aggSlots, curSlots) => {
      for (const slot of curSlots.slots) {
        const i = aggSlots.findIndex((aggSlot) => aggSlot.time.isSame(slot));

        if (i >= 0) {
          aggSlots[i].staffIds = [...aggSlots[i].staffIds, curSlots.staffId];
        } else {
          aggSlots.push({ time: slot, staffIds: [curSlots.staffId] });
        }
      }

      return [...aggSlots];
    }, [])
    .sort((a, b) => (dayjs(a.time).isAfter(dayjs(b.time)) ? 1 : -1));
};

import { useMemo } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
import { INewsCategory } from "@app/models";
import { filterStoryblokCollectionByLanguage } from "@app/helpers";

export const useInsightsCategories = () => {
  const { language } = useI18next();
  const { categories } = useStaticQuery(graphql`
    {
      categories: allStoryblokDatasourceEntry(
        filter: {
          data_source: { eq: "news-categories" }
          data_source_dimension: { ne: null }
        }
      ) {
        edges {
          node {
            uuid: value
            name: dimension_value
            lang: data_source_dimension
          }
        }
      }
    }
  `);

  return useMemo(
    () =>
      filterStoryblokCollectionByLanguage<INewsCategory>(
        language,
        categories.edges,
      ),
    [],
  );
};

export enum OsNames {
  Android = "Android",
  IOs = "iOS",
}

export enum StuffAvailabilityStatuses {
  Available = "available",
  Busy = "busy",
  Out = "outOfOffice",
}

export const DEFAULT_TIME_INTERVAL = "PT30M";
export const DEFAULT_FADE_DURATION = 500;

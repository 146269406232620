import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="27px"
    height="18px"
    viewBox="0 0 27 18"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <g id="Mobile" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <g id="Homepage" transform="translate(-18, -7300)">
        <g id="Footer-24-Copy" transform="translate(0, 6570)">
          <g id="Group-6" transform="translate(18, 728.9998)">
            <g id="Group-5" transform="translate(0, 1)">
              <path
                d="M26.4354571,2.81086614 C26.1244128,1.70377953 25.2107926,0.833858268 24.0483038,0.537637795 C21.9435874,0 13.4999173,0 13.4999173,0 C13.4999173,0 5.05641264,0 2.95153082,0.537637795 C1.78920743,0.833858268 0.875752546,1.70377953 0.564542899,2.81086614 C0,4.81527559 0,9 0,9 C0,9 0,13.1847244 0.564542899,15.1891339 C0.875752546,16.2962205 1.78920743,17.1661417 2.95153082,17.4623622 C5.05641264,18 13.4999173,18 13.4999173,18 C13.4999173,18 21.9435874,18 24.0483038,17.4623622 C25.2107926,17.1661417 26.1244128,16.2962205 26.4354571,15.1891339 C27,13.1847244 27,9 27,9 C27,9 26.9976849,4.81527559 26.4354571,2.81086614"
                id="Fill-1"
                fill="#FFFFFF"
              />
              <polygon
                id="Fill-3"
                fill="#111111"
                points="10.7974204 12.8568819 17.8118742 9.00034646 10.7974204 5.14381102"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SvgComponent;

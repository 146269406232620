import React, { FC } from "react";
import classNames from "classnames";

type Props = {
  data: string[];
  className?: string;
};

export const Marquee: FC<Props> = ({ data, className }) => (
  <div
    className={classNames(
      "group-hover:pause items-between flex shrink-0",
      className,
    )}
  >
    {data.map((label, i) => (
      <div key={i} className={classNames("shrink-0 pl-33vw", "lg:pl-20vw")}>
        {label}
      </div>
    ))}
  </div>
);

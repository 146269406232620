import React from "react";
import {
  render,
  NODE_PARAGRAPH,
  NODE_HEADING,
  MARK_SUBSCRIPT,
  MARK_SUPERSCRIPT,
} from "storyblok-rich-text-react-renderer";
import classNames from "classnames";
import { RichText } from "@app/components/base-page/models";
import { IRichText, TextType } from "@app/models/storyblok/basic";

export enum HeaderLevel {
  H1 = "h1",
  H2 = "h2",
  H3 = "h3",
  H4 = "h4",
  H5 = "h5",
  H6 = "h6",
}

type Props = {
  richText: RichText | IRichText<TextType> | string;
  headerLevel?: HeaderLevel;
  className?: string;
};

export const BlockHeader: React.FC<Props> = ({
  richText,
  headerLevel = HeaderLevel.H3,
  className,
}) =>
  React.createElement(
    headerLevel,
    {
      className: classNames(
        className,
        "[&_img]:inline-block [&_img]:max-h-[1em]",
        "[&_img]:px-[.25em] [&_img]:align-baseline",
      ),
    },
    render(richText, {
      nodeResolvers: {
        [NODE_PARAGRAPH]: (children) =>
          React.createElement(React.Fragment, null, children),
        [NODE_HEADING]: (children) =>
          React.createElement(React.Fragment, null, children),
      },
      markResolvers: {
        [MARK_SUBSCRIPT]: (children) =>
          React.createElement("sub", null, children),
        [MARK_SUPERSCRIPT]: (children) =>
          React.createElement("sup", null, children),
      },
      defaultStringResolver: (children) =>
        React.createElement(React.Fragment, null, children),
    }),
  );

import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" {...props}>
    <g fill="none" fillRule="evenodd" stroke="#FFF" strokeWidth={1.875}>
      <path d="M6.79 19.293h16.579M23.369 10.708H6.789M19.562 6.901l3.807 3.807-3.807 3.807M10.596 15.487l-3.807 3.806 3.807 3.807" />
    </g>
  </svg>
);

export default SvgComponent;

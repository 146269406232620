import React, { FC, useRef } from "react";
import classNames from "classnames";
import { yupResolver } from "@hookform/resolvers/yup";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useTranslation, Trans } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import {
  Dropdown,
  Input,
  PhoneInput,
  Textarea,
  Checkbox,
} from "@app/components/common/form";
import { Close } from "@app/components/common/icons";
import { Dialog } from "@app/constants/ui";
import { ModalState } from "@app/enums/modal";
import { ComplaintFormData } from "@app/types/forms";
import { useOutsideClick, useModal } from "@app/hooks";
import { Button } from "../../../button/Button";
import { RECAPTCHA_ACTION } from "./constants";
import { useComplaint } from "./useComplaint";
import { useValidationSchema } from "./useValidationSchema";

export const Complaint: FC = () => {
  const { t } = useTranslation("complaint-modal");
  const { onChangeModalState } = useModal();
  const { sendComplaintRequest } = useComplaint();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const ref = useRef<HTMLDivElement>(null);
  const validationSchema = useValidationSchema(t);
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      phoneCode: {
        alphaCode: "CH",
        code: "41",
      },
    },
  });

  const onClose = () => {
    onChangeModalState(ModalState.IDLE);
  };

  useOutsideClick(ref, onClose);

  const onSubmit = async (data: ComplaintFormData) => {
    try {
      const recaptchaToken = await executeRecaptcha(RECAPTCHA_ACTION);

      await sendComplaintRequest(data, recaptchaToken, RECAPTCHA_ACTION);

      onChangeModalState(ModalState.SUCCESS);
    } catch (error) {
      onChangeModalState(ModalState.ERROR);
    }
  };

  return (
    <dialog id={Dialog.SUBSCIBE} className="modal modal-open z-[1000]">
      <div
        className={classNames(
          "modal-box relative max-h-screen w-screen rounded-none bg-dark px-5 pb-10 pt-15 text-primary-content",
          "scrollbar-thumb-rounded overflow-auto scrollbar scrollbar-thin scrollbar-track-transparent scrollbar-thumb-base-400",
          "md:max-h-[calc(100vh-2em)] md:rounded-lg md:px-10 md:pb-15",
          "xl:w-full xl:max-w-[600px] xl:px-20",
        )}
        ref={ref}
      >
        <h3
          className={classNames(
            "mb-5 text-large",
            "xl:mb-2.5 xl:text-center xl:text-large-tall",
          )}
        >
          {t("hero_title")}
        </h3>
        <p
          className={classNames(
            "mb-15 text-base-400",
            "xl:text-center xl:text-medium-standart",
          )}
        >
          {t("form_intro")}
        </p>
        <FormProvider {...methods}>
          <div
            className={classNames(
              "px-5",
              "lg:px-16",
              "xl:mx-auto xl:max-w-[720px] xl:px-0",
            )}
          >
            <form
              onSubmit={methods.handleSubmit(onSubmit)}
              className={classNames(
                "space-y-5",
                "[&_a:hover]:text-primary-content",
              )}
            >
              <Input name="firstName" label={`${t("form_field_name")} *`} />
              <Input name="lastName" label={`${t("form_field_surname")} *`} />
              <Input name="address" label={`${t("form_field_address")} *`} />
              <Input name="email" label={`${t("form_field_email")} *`} />
              <div className="flex gap-x-5">
                <Input
                  name="postalCode"
                  label={`${t("form_field_postal_code")} *`}
                />
                <Input name="city" label={`${t("form_field_city")} *`} />
              </div>
              <PhoneInput
                phoneName="phone"
                codeName="phoneCode"
                label={`${t("form_field_phone")} *`}
                dropdownBgVariant="grey"
              />
              <Dropdown
                name="service"
                label={`${t("form_field_subject_label")} *`}
                options={(
                  t("form_field_subject_items", {
                    returnObjects: true,
                  }) as string[]
                ).map((option) => {
                  return { value: option, label: option };
                })}
                dropdownBgVariant="grey"
              />
              <Input name="company" label={`${t("form_field_company")} *`} />
              <Textarea name="message" label={`${t("form_field_message")} *`} />
              <Checkbox
                name="isTermsAccepted"
                label={
                  <Trans
                    t={t}
                    i18nKey="form_field_terms"
                    components={[
                      <a
                        key="privacy-policy"
                        href="/legal/privacy-policy"
                        target="_blank"
                        rel="noopener noreferrer"
                      />,
                    ]}
                  />
                }
              />
              <div
                className={classNames(
                  "flex justify-center pt-5",
                  "md:col-span-2",
                )}
              >
                <Button
                  label={t("form_button")}
                  type="submit"
                  isLoading={methods.formState.isSubmitting}
                />
              </div>
            </form>
          </div>
        </FormProvider>
        <Close
          className={classNames(
            "absolute right-5 top-5 h-5 w-5 cursor-pointer duration-300",
            "lg:right-6 lg:top-6 lg:h-6 lg:w-6",
            "hover:opacity-75",
          )}
          onClick={onClose}
        />
      </div>
    </dialog>
  );
};

import React from "react";
import classNames from "classnames";
import { Link } from "@app/components/common";

type Props = {
  title: string;
  items: {
    label: string;
    link: string;
  }[];
};

export const AccordionMenu: React.FC<Props> = ({ title, items }) => (
  <div
    className={classNames(
      "collapse collapse-arrow rounded-none text-primary-content",
    )}
  >
    <input type="radio" name="accordion" aria-label="Accordion menu" />
    <div className="collapse-title pl-0">
      <h4 className="text-medium-low">{title}</h4>
    </div>
    <nav className="collapse-content p-0">
      <ul className="pt-2.5 text-neutral-content">
        {items.map((item) => (
          <li key={item.label}>
            {item.link.includes("http") ? (
              <a
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
                className={classNames(
                  "text-small-tall no-underline duration-300 hover:text-primary-content",
                )}
              >
                {item.label}
              </a>
            ) : (
              <Link
                to={item.link}
                className={classNames(
                  "text-small-tall no-underline duration-300 hover:text-primary-content",
                )}
              >
                {item.label}
              </Link>
            )}
          </li>
        ))}
      </ul>
    </nav>
  </div>
);

import React, { FC } from "react";
import { useInsightsCategories } from "@app/hooks";

export interface CategoryLabelProps {
  categoryId: string;
}

export const CategoryLabel: FC<CategoryLabelProps> = ({ categoryId }) => {
  const categories = useInsightsCategories();
  const category = categories[categoryId];

  return (
    <div
      className={
        "inline rounded-[3px] bg-dark p-2 text-small text-neutral-content"
      }
    >
      {category?.name}
    </div>
  );
};

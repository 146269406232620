import React, { FC } from "react";
import classNames from "classnames";
import { Trans, useTranslation } from "react-i18next";
import { Input, Checkbox } from "@app/components/common/form";

type Props = {
  isDataLoading: boolean;
};

export const Additional: FC<Props> = ({ isDataLoading }) => {
  const { t } = useTranslation("schedule-a-call-modal");

  return (
    <div>
      <h4
        className={classNames(
          "mb-5 text-regular",
          "xl:mb-10 xl:text-medium-standart",
        )}
      >
        {t("additional")}
      </h4>
      <div className="space-y-5 [&_a:hover]:text-primary-content">
        <Input
          name="howDidYouHear"
          label={t("how-did-you-hear")}
          placeholder={t("how-did-you-hear-placeholder")}
          isDisabled={isDataLoading}
        />
        <Input
          name="note"
          label={t("how-can-we-help")}
          placeholder={t("how-can-we-help-placeholder")}
          isDisabled={isDataLoading}
        />
        <Checkbox
          name="isSwissResident"
          label={t("is-resident")}
          isDisabled={isDataLoading}
        />
        <Checkbox
          name="isTermsAccepted"
          label={
            <Trans
              t={t}
              i18nKey="accept-communication-terms"
              components={[
                <a
                  key="privacy-policy"
                  href="/legal/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                />,
              ]}
            />
          }
          isDisabled={isDataLoading}
        />
      </div>
    </div>
  );
};

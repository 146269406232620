import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog } from "@app/constants/ui";
import { useCommonTranslation, useCookieConsent } from "@app/hooks";
import { CookieSettingsModal } from "./components/cookies-settings-modal/CookieSettingsModal";

export const CookieBanner = () => {
  const { t } = useCommonTranslation();
  const { cookieBannerIsOpen, closeCookieBanner } = useCookieConsent();
  const [animate, setAnimate] = useState(false);

  const handleClose = () => {
    closeCookieBanner();
  };

  const openSettingsModal = () => {
    (
      document.getElementById(Dialog.COOKIES_SETTINGS) as HTMLDialogElement
    ).showModal();
  };

  useEffect(() => {
    let timeoutId;

    if (cookieBannerIsOpen) {
      timeoutId = window.setTimeout(() => setAnimate(true), 1000);
    }

    return () => window.clearTimeout(timeoutId);
  }, [cookieBannerIsOpen]);

  return (
    <>
      <div
        className={classNames(
          "invisible fixed bottom-7 left-3 right-3 z-[100] m-auto flex max-w-container items-center justify-center",
          { "animate-slide-up-cookies-banner": animate },
          { hidden: !cookieBannerIsOpen },
        )}
      >
        <div className="flex flex-row items-center justify-center gap-7 rounded-sm bg-white px-6 py-3">
          <div>
            <p className="inline">
              {t("cookies")}
              <span className="mr-7" />
            </p>
            <button
              className={classNames("inline underline")}
              onClick={openSettingsModal}
            >
              {t("cookies_settings")}
            </button>
          </div>
          <button
            className="flex h-9 w-9 shrink-0 items-center justify-center rounded-sm bg-[#ededed] text-grey-neutral"
            onClick={handleClose}
            aria-label="Close"
          >
            <FontAwesomeIcon fontSize={20} width={20} icon={faTimes} />
          </button>
        </div>
      </div>
      <CookieSettingsModal />
    </>
  );
};

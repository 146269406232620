import _data from "../../data/data.json";
import _dialCodes from "../../data/dial_codes.json";

export interface Data {
  iosLink: string;
  androidLink: string;
  linkedInLink: string;
  linkedInLinkCianni: string;
  instagramLink: string;
  youtubeLink: string;
  twitterLink: string;
  twitterHandle: string;
  officeAddress: string[];
  officeDirectionsLink: string;
  contactEmail: string;
  taxDetails: string[];
  callToActionLink: string;
  roi: string;
}

export interface DialCode {
  name: string;
  dial_code: string;
  code: string;
}

export const data: Data = _data;
export const dialCodes: DialCode[] = _dialCodes;

import React, { FC } from "react";
import classNames from "classnames";
import { useFormContext } from "react-hook-form";
import { ChevronDown, Spinner } from "@app/components/common/icons";

type Props = {
  name: string;
  placeholder: string;
};

export const PhoneInput: FC<Props> = ({ name, placeholder }) => {
  const { register, formState } = useFormContext();

  return (
    <div className="grow">
      <div
        className={classNames(
          "flex items-center rounded-sm border duration-300",
          "has-[:focus]:border-primary-content has-[:focus]:shadow-md",
          formState.errors[name] ? "border-error" : "border-divider",
          { "cursor-not-allowed opacity-50": formState.isSubmitting },
        )}
      >
        <input
          type="tel"
          {...register(name)}
          placeholder={placeholder}
          className={classNames(
            "w-full rounded-l-sm bg-transparent px-4 py-2.5 text-regular outline-none",
            "placeholder:text-regular placeholder:text-neutral-content",
            { "cursor-not-allowed": formState.isSubmitting },
          )}
          disabled={formState.isSubmitting}
        />
        <button
          type="submit"
          className={classNames(
            "rounded-r-sm px-4 py-2.5 duration-300",
            formState.isSubmitting ? "cursor-not-allowed" : "hover:opacity-75",
          )}
          disabled={formState.isSubmitting}
        >
          <div className="flex h-4 w-4 items-center justify-center">
            {formState.isSubmitting ? (
              <Spinner className="h-4 w-4 stroke-primary-content" />
            ) : (
              <ChevronDown className="h-3 w-3 -rotate-90 fill-primary-content" />
            )}
          </div>
        </button>
      </div>
      <div className="relative">
        {formState.errors[name] && (
          <p
            role="alert"
            className="absolute top-1 text-start text-extra-small-low text-error"
          >
            {formState.errors[name]?.message.toString()}
          </p>
        )}
      </div>
    </div>
  );
};

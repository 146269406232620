import { useI18next } from "gatsby-plugin-react-i18next";
import { useTranslation } from "react-i18next";

export const usePageTranslation = () => {
  const { originalPath } = useI18next();
  const path = originalPath
    .replace(/\/$/, "")
    .replace(/\//, "_")
    .replace(/\//, "_");

  const { t } = useTranslation(`p${path}`);

  return { t };
};

import { useMemo } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { all } from "country-codes-list";
import { CountriesDataVariant } from "@app/enums/countries";
import { CountryData } from "./types";
import { COUNTRIES_TO_HIDE, PRIORITY_COUNTRIES } from "./constants";

const getCountryList = (
  variant = CountriesDataVariant.ALL,
  priorityCountryCodes = PRIORITY_COUNTRIES,
  countriesToHide = COUNTRIES_TO_HIDE,
) => {
  const countries = all()
    .filter((country) => !countriesToHide.includes(country.countryCode))
    .map((country) => ({
      code: country.countryCode,
      phoneCode: country.countryCallingCode,
    }));

  const priorityCountries = priorityCountryCodes.map((priorityCountry) => ({
    code: priorityCountry,
    phoneCode:
      countries.filter((country) => country.code === priorityCountry)[0]
        ?.phoneCode ?? "",
  }));

  if (variant === CountriesDataVariant.SWITZERLAND_AND_NEIGHBORS) {
    return priorityCountries;
  }

  const otherCountries = countries
    .filter((country) => !priorityCountryCodes.includes(country.code))
    .sort((a, b) => +a.phoneCode - +b.phoneCode);

  return [...priorityCountries, ...otherCountries];
};

export const useCountriesData = (dataVariant?: CountriesDataVariant) => {
  const iconsData = useStaticQuery<CountryData>(graphql`
    query {
      allFile(
        filter: {
          sourceInstanceName: { eq: "images" }
          relativeDirectory: { eq: "flags" }
        }
      ) {
        edges {
          node {
            publicURL
            name
          }
        }
      }
    }
  `);

  const countriesData = useMemo(
    () =>
      getCountryList(dataVariant).map((option) => ({
        ...option,
        iconPath:
          iconsData.allFile.edges.filter(
            (flag) => flag.node.name?.toUpperCase() === option.code,
          )[0]?.node.publicURL ?? iconsData.allFile.edges[0].node.publicURL,
      })),
    [],
  );

  return countriesData;
};

import React, { FC } from "react";
import classNames from "classnames";
import { useFormContext, useController } from "react-hook-form";
import { ChevronDown } from "../../icons";

type Option = {
  value: string | number | boolean;
  label?: string;
};

type Props = {
  name: string;
  label: string;
  options: Option[];
  isDisabled?: boolean;
  dropdownBgVariant?: "grey" | "black";
};

export const Dropdown: FC<Props> = ({
  name,
  label,
  options,
  isDisabled,
  dropdownBgVariant,
}) => {
  const { control, formState } = useFormContext();
  const { field } = useController({
    name,
    control,
  });

  const onChangeValue = (option: Option) => {
    field.onChange(option.value);
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  };

  const onKeyDown = (
    event: React.KeyboardEvent<HTMLElement>,
    value: Option,
  ) => {
    if (event.key === "Enter") {
      onChangeValue(value);
    }
  };

  return (
    <div className="dropdown dropdown-end dropdown-bottom w-full">
      {label ? (
        <label className="flex px-0 pb-2.5 pt-0">
          <span className="text-regular text-base-400">{label}</span>
        </label>
      ) : null}
      <button
        tabIndex={0}
        type="button"
        className={classNames(
          "flex min-h-[50px] w-full items-center justify-between gap-x-4 px-4 py-2.5",
          "rounded-sm border text-regular text-base-400",
          {
            "group duration-300 focus:border-primary-content focus:shadow-md":
              !isDisabled || !formState.isSubmitting,
          },
          {
            "cursor-not-allowed [&:disabled]:border-divider [&:disabled]:bg-dark [&:disabled]:text-base-400 [&:disabled]:opacity-50":
              isDisabled || formState.isSubmitting,
          },
          { "[&:disabled]:bg-transparent": dropdownBgVariant === "grey" },
          { "[&:disabled]:bg-dark": dropdownBgVariant !== "grey" },
          { "border-error": formState.errors[name] },
          { "border-divider": !formState.errors[name] },
        )}
        disabled={isDisabled || formState.isSubmitting}
        ref={field.ref}
        onBlur={field.onBlur}
      >
        <span className="truncate">
          {options.filter((option) => option.value === field.value)[0]?.label}
        </span>
        <ChevronDown
          className={classNames(
            "h-3 w-3 shrink-0",
            isDisabled || formState.isSubmitting
              ? "fill-base-400"
              : "fill-primary-content duration-300 group-focus:-scale-100",
          )}
        />
      </button>
      <ul
        tabIndex={0}
        className={classNames(
          "menu dropdown-content z-10 mt-2.5 w-full cursor-pointer space-y-3 px-4 py-2.5",
          "rounded-sm border border-divider bg-base-100 text-regular text-base-400",
          { hidden: isDisabled || formState.isSubmitting },
          { "bg-dark": dropdownBgVariant === "grey" },
          { "bg-base-100": dropdownBgVariant !== "grey" },
        )}
      >
        {options.map((option) => (
          <li
            key={option.value.toString()}
            tabIndex={0}
            onClick={() => onChangeValue(option)}
            onKeyDown={(event) => onKeyDown(event, option)}
            className="overflow-clip duration-300 hover:text-primary-content"
          >
            {option.label ?? option.value.toString()}
          </li>
        ))}
      </ul>
      <div className="label relative p-0">
        {formState.errors[name] ? (
          <p
            role="alert"
            className={classNames(
              "label-text-alt absolute top-1 text-extra-small-low text-error",
            )}
          >
            {formState.errors[name]?.message.toString()}
          </p>
        ) : null}
      </div>
    </div>
  );
};

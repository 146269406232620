import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="21px"
    height="21px"
    viewBox="0 0 21 21"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <g
      id="Desktop"
      stroke="none"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Homepage"
        transform="translate(-1394, -9084)"
        fill="#FFFFFF"
        fillRule="nonzero"
      >
        <g id="Footer" transform="translate(0, 8438)">
          <g id="X_logo_2023_original" transform="translate(1394, 646)">
            <path
              d="M12.4999,8.89308909 L20.3189,0 L18.4667,0 L11.6746,7.72016653 L6.2538,0 L0,0 L8.1991,11.6753705 L0,21 L1.8522,21 L9.0202,12.8454954 L14.7462,21 L21,21 M2.5207,1.36666112 L5.3662,1.36666112 L18.4653,19.7004829 L15.6191,19.7004829"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SvgComponent;

import { useEffect, useState } from "react";
import { getUserOSName } from "@app/utils";

export const useOSName = () => {
  const [osName, setOSName] = useState("");

  useEffect(() => {
    setOSName(getUserOSName());
  }, []);

  return osName;
};

import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width={40} height={40} viewBox="0 0 10.583333 10.583334" {...props}>
    <defs id="defs2" />
    <g id="layer1">
      <circle
        style={{
          fill: "#033e37",
          strokeWidth: 0.264583,
          fillOpacity: 1,
        }}
        id="path858"
        cx={5.2716608}
        cy={5.3163357}
        r={5.1823106}
      />
      <path
        style={{
          fill: "#000000",
          strokeWidth: 0.264583,
        }}
        id="path960"
        d=""
      />
      <path
        style={{
          fill: "#000000",
          strokeWidth: 0.264583,
        }}
        id="path940"
        d=""
      />
      <path
        style={{
          fill: "#000000",
          strokeWidth: 0.264583,
        }}
        id="path1098"
        d=""
      />
      <path
        style={{
          fill: "#000000",
          strokeWidth: 0.264583,
        }}
        id="path1683"
        d=""
      />
      <path
        id="rect1725"
        style={{
          fill: "#ffffff",
          fillOpacity: 1,
          strokeWidth: 0.239819,
        }}
        d="m 7.0354852,3.3609711 -2.634266,2.634265 -1.059515,-1.059514 -0.629994,0.629994 1.059515,1.059047 0.629526,0.629993 0.629994,-0.629993 2.634265,-2.634267 z"
      />
    </g>
  </svg>
);

export default SvgComponent;

import { TFunction } from "react-i18next";
import * as yup from "yup";
import { FIELDS_MAX_LENGTH } from "@app/constants/form-validation";
import { Service, TimeSlot } from "@app/types/forms";

export const useValidationSchema = (t: TFunction<"translation", string>) =>
  yup
    .object({
      service: yup.object<Service>().required(t("invalid-service")),
      date: yup.date().required(t("invalid-field")),
      timeslot: yup.object<TimeSlot>().required(t("invalid-timeslot")),
      name: yup
        .string()
        .trim()
        .max(FIELDS_MAX_LENGTH.FIRST_NAME)
        .required(t("invalid-field")),
      lastName: yup
        .string()
        .trim()
        .max(FIELDS_MAX_LENGTH.LAST_NAME)
        .required(t("invalid-field")),
      email: yup.string().trim().email().required(t("invalid-field")),
      phoneCode: yup.object({
        alphaCode: yup.string(),
        code: yup.string(),
      }),
      phone: yup
        .string()
        .matches(/^(?=.*\d)[\d\s]{2,32}$/, {
          message: t("invalid-phone"),
          excludeEmptyString: false,
        })
        .required(t("invalid-field")),
      howDidYouHear: yup
        .string()
        .trim()
        .max(FIELDS_MAX_LENGTH.HOW_DID_YOU_HEAR_ABOUT_US)
        .required(t("invalid-field")),
      note: yup
        .string()
        .trim()
        .max(FIELDS_MAX_LENGTH.MESSAGE)
        .required(t("invalid-field")),
      isSwissResident: yup.boolean().required().oneOf([true]),
      isTermsAccepted: yup.boolean().required().oneOf([true]),
    })
    .required();

import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    id="svg827"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <defs id="defs824" />
    <g id="layer1">
      <path
        id="path1924"
        style={{
          fill: "#ffffff",
          strokeWidth: 0.800001,
        }}
        d="m 408.5957,214.58984 v 1.19922 1.19922 h -2.74218 -2.74414 l -1.25586,1.25781 -1.25782,1.25782 v 11.19531 11.19531 l 0.82813,1.54688 0.82812,1.54687 h 14.34375 14.34375 l 0.82813,-1.54687 0.82812,-1.54688 v -11.19531 -11.19531 l -1.25781,-1.25782 -1.25586,-1.25781 h -2.74414 -2.74219 v -1.19922 -1.19922 h -1.19922 -1.20117 v 1.19922 1.19922 h -5.59961 -5.59961 v -1.19922 -1.19922 h -1.19921 z m -4.79882,4.79883 h 2.07421 2.07422 l 0.41797,1.59961 0.41992,1.60156 h 0.78125 0.78125 l 0.41797,-1.60156 0.41797,-1.59961 h 5.41406 5.41407 l 0.41796,1.59961 0.41797,1.60156 h 0.78125 0.78321 l 0.41796,-1.60156 0.41797,-1.59961 h 2.07422 2.07617 v 2.80078 2.79883 H 416.5957 403.79688 v -2.79883 z m -0.0195,7.82227 h 25.63282 v 14.44726 h -25.63282 z"
        transform="scale(0.26458333)"
      />
      <path
        id="path2397"
        style={{
          fill: "#ffffff",
          strokeWidth: 0.455337,
        }}
        d="M 5.5437805,1.3104835 V 1.993044 2.67672 H 3.9818974 2.4211299 L 1.705216,3.3915183 0.99041778,4.1074264 v 6.3720386 6.372038 l 0.47134702,0.880438 0.4713414,0.881549 h 8.1640368 8.164037 l 0.471347,-0.881549 0.471342,-0.880438 V 10.479465 4.1074264 L 18.487961,3.3915183 17.772052,2.67672 H 16.211279 14.650506 V 1.993044 1.3104835 h -0.68367 -0.682561 V 1.993044 2.67672 H 10.097143 6.9089014 V 1.993044 1.3104835 H 6.226341 Z M 2.8113189,4.0418352 H 3.9919034 5.1735978 L 5.4114939,4.9534014 5.6493901,5.8638462 H 6.0940544 6.5387187 L 6.7777247,4.9534014 7.0156208,4.0418352 h 3.0815222 3.080413 l 0.237896,0.9115662 0.239006,0.9104448 h 0.444664 0.444664 l 0.237897,-0.9104448 0.237896,-0.9115662 h 1.181694 1.180585 V 5.6359561 7.2300827 H 10.097143 2.8113189 V 5.6359561 Z m 0,4.0986924 h 7.2858241 7.284715 V 12.69389 17.247253 H 10.097143 2.8113189 V 12.69389 Z"
      />
      <path
        style={{
          fill: "#ffffff",
          strokeWidth: 0.455337,
        }}
        d="m 14.73779,11.423647 h 0.455322 V 10.96831 10.512973 H 14.73779 14.282446 v 0.455337 0.455337 z"
        id="path2411"
      />
      <path
        style={{
          fill: "#ffffff",
          strokeWidth: 0.455337,
        }}
        d="m 10.18442,11.423647 h 0.455343 V 10.96831 10.512973 H 10.18442 9.7290758 v 0.455337 0.455337 z"
        id="path2409"
      />
      <path
        style={{
          fill: "#ffffff",
          strokeWidth: 0.455337,
        }}
        d="M 5.6310497,11.423647 H 6.0863931 V 10.96831 10.512973 H 5.6310497 5.1757278 v 0.455337 0.455337 z"
        id="path2407"
      />
      <path
        style={{
          fill: "#ffffff",
          strokeWidth: 0.455337,
        }}
        d="m 14.73779,15.066337 h 0.455322 V 14.611 14.155665 H 14.73779 14.282446 V 14.611 15.066337 Z"
        id="path2405"
      />
      <path
        style={{
          fill: "#ffffff",
          strokeWidth: 0.455337,
        }}
        d="m 10.18442,15.066337 h 0.455343 V 14.611 14.155665 H 10.18442 9.7290758 V 14.611 15.066337 Z"
        id="path2403"
      />
      <path
        style={{
          fill: "#ffffff",
          strokeWidth: 0.455337,
        }}
        d="M 5.6310497,15.066337 H 6.0863931 V 14.611 14.155665 H 5.6310497 5.1757278 V 14.611 15.066337 Z"
        id="path2401"
      />
      <path
        style={{
          fill: "#ffffff",
          strokeWidth: 0.211667,
        }}
        d="m 112.53693,61.237446 v -0.211667 h -0.21166 -0.21167 v 0.211667 0.211667 h 0.21167 0.21166 z"
        id="path1256"
      />
      <path
        style={{
          fill: "#ffffff",
          strokeWidth: 0.211667,
        }}
        d="m 110.42027,61.237446 v -0.211667 h -0.21167 -0.21167 v 0.211667 0.211667 h 0.21167 0.21167 z"
        id="path1254"
      />
      <path
        style={{
          fill: "#ffffff",
          strokeWidth: 0.211667,
        }}
        d="m 108.3036,61.237446 v -0.211667 h -0.21167 -0.21166 v 0.211667 0.211667 h 0.21166 0.21167 z"
        id="path1252"
      />
      <path
        style={{
          fill: "#ffffff",
          strokeWidth: 0.211667,
        }}
        d="m 112.53693,62.930779 v -0.211666 h -0.21166 -0.21167 v 0.211666 0.211667 h 0.21167 0.21166 z"
        id="path1250"
      />
      <path
        style={{
          fill: "#ffffff",
          strokeWidth: 0.211667,
        }}
        d="m 110.42027,62.930779 v -0.211666 h -0.21167 -0.21167 v 0.211666 0.211667 h 0.21167 0.21167 z"
        id="path1248"
      />
      <path
        style={{
          fill: "#ffffff",
          strokeWidth: 0.211667,
        }}
        d="m 108.3036,62.930779 v -0.211666 h -0.21167 -0.21166 v 0.211666 0.211667 h 0.21166 0.21167 z"
        id="path1246"
      />
    </g>
  </svg>
);

export default SvgComponent;

export enum ModalState {
  IDLE = "idle",
  FINANCIAL_REPORT_SUCCESS = "financial-report-success",
  SCHEDULE_A_CALL = "schedule-a-call",
  SCHEDULE_A_CALL_SUCCESS = "schedule-a-call-success",
  SUBSCRIBE = "subscribe",
  SUBSCRIBE_SUCCESS = "subscribe-success",
  DOWNLOAD_APP = "download-app",
  SUCCESS = "success",
  ERROR = "error",
  LOST_CARD_SUCCESS = "lost-card-success",
  SMS_SUCCESS = "sms-success",
  COMPLAINT = "complaint",
  SIMPLE_CONTACT_SUCCESS = "simple-contact-success",
}

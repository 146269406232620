import React, { FC } from "react";
import classNames from "classnames";
import { useFormContext, useController } from "react-hook-form";
import { Spinner } from "@app/components/common/icons";
import { Service } from "@app/types/forms";
import { DEFAULT_TIME_INTERVAL } from "@app/constants";
import { parseTimeIntervalToMinutes } from "@app/helpers";

type Props = {
  name: string;
  label: string;
  services: Service[];
  isDataLoading?: boolean;
  onChangeService?: (service: Service) => void;
};

export const PickService: FC<Props> = ({
  name,
  label,
  services,
  isDataLoading,
  onChangeService,
}) => {
  const { control, formState } = useFormContext();
  const { field } = useController({
    name,
    control,
  });

  const onChangeValue = (value: Service) => {
    if (!isDataLoading && !formState.isSubmitting) {
      field.onChange(value);
      onChangeService(value);
    }
  };

  const onKeyDown = (
    event: React.KeyboardEvent<HTMLElement>,
    value: Service,
  ) => {
    if (event.key === "Enter") {
      onChangeValue(value);
    }
  };

  return (
    <div>
      <h4
        className={classNames(
          "mb-5 text-regular",
          "xl:mb-10 xl:text-medium-standart",
        )}
      >
        {label}
      </h4>
      <div className="space-y-5">
        {isDataLoading && !field.value ? (
          <div className="flex justify-center py-5">
            <Spinner className="h-6 w-6 stroke-primary-content" />
          </div>
        ) : (
          services?.map((service) => (
            <button
              key={service.id}
              tabIndex={0}
              type="button"
              onClick={() => onChangeValue(service)}
              onKeyDown={(event) => onKeyDown(event, service)}
              className={classNames(
                "flex w-full items-center justify-between gap-7 rounded-sm border px-5 py-5 duration-300",
                "xl:gap-10 xl:px-7 xl:py-4",
                formState.isSubmitting
                  ? "cursor-not-allowed opacity-50"
                  : "cursor-pointer",
                field.value?.id === service.id
                  ? "border-primary-content focus:shadow-md"
                  : "border-divider",
              )}
              disabled={formState.isSubmitting}
            >
              <div className="flex-1 text-start">
                <h5 className="text-regular">{service.displayName}</h5>
                <p
                  className={classNames(
                    "hidden text-extra-small text-base-400",
                    "xl:block xl:text-regular",
                  )}
                >
                  {service.description}
                </p>
              </div>
              <div
                className={classNames(
                  "whitespace-nowrap text-extra-small-tall duration-300",
                  "xl:text-regular",
                  field.value?.id === service.id
                    ? "text-primary-content"
                    : "text-base-400",
                )}
              >
                {parseTimeIntervalToMinutes(
                  service.defaultDuration ?? DEFAULT_TIME_INTERVAL,
                )}{" "}
                min
              </div>
            </button>
          ))
        )}
      </div>
      <div className="label relative p-0">
        {formState.errors[name] ? (
          <p
            role="alert"
            className={classNames(
              "label-text-alt absolute top-1 text-extra-small-low text-error",
            )}
          >
            {formState.errors[name]?.message.toString()}
          </p>
        ) : null}
      </div>
    </div>
  );
};

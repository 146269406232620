import React, { FC } from "react";
import dayjs, { Dayjs } from "dayjs";
import Calendar from "react-calendar";
import classNames from "classnames";
import { useFormContext, useController } from "react-hook-form";
import { Calendar as CalendarIcon } from "@app/components/common/icons";
import { getStartOfDay } from "@app/helpers";

type Props = {
  label: string;
  name: string;
  locale: string;
  isDisabled?: boolean;
  datesToShow: Dayjs[];
};

export const DropdownCalendar: FC<Props> = ({
  label,
  name,
  locale,
  isDisabled,
  datesToShow,
}) => {
  const { control, formState, watch } = useFormContext();
  const { field } = useController({
    name,
    control,
  });
  const isServiceSelected = watch("service");

  return (
    <div className="dropdown dropdown-end dropdown-bottom mb-5 w-full">
      {label ? (
        <label className="flex px-0 pb-2.5 pt-0">
          <span className="text-regular text-base-400">{label}</span>
        </label>
      ) : null}
      <button
        tabIndex={0}
        type="button"
        className={classNames(
          "flex w-full items-center justify-between px-4 py-2.5",
          "rounded-sm border border-divider text-regular text-base-400",
          {
            "group duration-300 focus:border-primary-content focus:shadow-md":
              !isDisabled || !formState.isSubmitting || isServiceSelected,
          },
          {
            "cursor-not-allowed opacity-75":
              isDisabled || formState.isSubmitting || !isServiceSelected,
          },
        )}
        disabled={isDisabled || formState.isSubmitting || !isServiceSelected}
      >
        <span>{dayjs(field.value).format("DD.MM.YYYY")}</span>
        <CalendarIcon className={classNames("h-5 w-5")} />
      </button>
      <div
        className={classNames(
          "menu dropdown-content z-10 mt-2.5 w-[320px] cursor-pointer px-4 py-2.5",
          "rounded-sm border border-divider bg-dark text-regular text-base-400",
          { hidden: isDisabled || formState.isSubmitting },
        )}
        ref={field.ref}
        onBlur={field.onBlur}
      >
        <Calendar
          value={field.value}
          onChange={(val) => {
            field.onChange(val as Date);
            if (document.activeElement instanceof HTMLElement) {
              document.activeElement.blur();
            }
          }}
          view="month"
          prevLabel={<div className={"prev--arrow"}></div>}
          nextLabel={<div className={"next--arrow"}></div>}
          next2Label={null}
          prev2Label={null}
          locale={locale}
          tileDisabled={({ date }) =>
            !datesToShow?.some((dateToShow) =>
              getStartOfDay(date).isSame(dateToShow),
            )
          }
          className={"box-border text-extra-small-medium"}
        />
      </div>
    </div>
  );
};

import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} viewBox="0 0 5.2916665 5.2916668" {...props}>
    <defs id="defs824" />
    <g id="layer1">
      <path
        id="rect910"
        style={{
          strokeWidth: 0.207497,
        }}
        d="M 0.81413715,1.127063 0.23381083,1.7073893 2.090545,3.5641235 2.6708713,4.1444497 3.2511978,3.5641235 5.1079319,1.7073893 4.5276054,1.127063 2.6708713,2.9837972 Z"
      />
    </g>
  </svg>
);

export default SvgComponent;

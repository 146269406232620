export const FIELDS_MAX_LENGTH = {
  FIRST_NAME: 32,
  LAST_NAME: 32,
  ADDRESS: 32,
  POSTAL_CODE: 16,
  CITY: 32,
  PHONE_CODE: 16,
  PHONE: 32,
  SERVICE: 64,
  SMS: 256,
  COMPANY: 32,
  HOW_DID_YOU_HEAR_ABOUT_US: 5000,
  MESSAGE: 5000,
  MS_ID: 128,
  MS_DATE: 64,
  BOOLEAN_AS_TEXT: 16,
  GENDER: 16,
  DATE_OF_BIRTH: 10,
  REPORT: 64,
};

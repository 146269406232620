import { useMemo } from "react";
import { TFunction } from "react-i18next";
import * as yup from "yup";
import { FIELDS_MAX_LENGTH } from "@app/constants/form-validation";

export const useValidationSchema = (t: TFunction<"translation", string>) =>
  useMemo(() => {
    return yup.object({
      firstName: yup
        .string()
        .trim()
        .max(FIELDS_MAX_LENGTH.FIRST_NAME)
        .required(t("invalid-field")),
      lastName: yup
        .string()
        .trim()
        .max(FIELDS_MAX_LENGTH.LAST_NAME)
        .required(t("invalid-field")),
      address: yup
        .string()
        .trim()
        .max(FIELDS_MAX_LENGTH.ADDRESS)
        .required(t("invalid-field")),
      email: yup
        .string()
        .trim()
        .required(t("invalid-field"))
        .email(t("invalid-email")),
      postalCode: yup
        .string()
        .trim()
        .max(FIELDS_MAX_LENGTH.POSTAL_CODE)
        .required(t("invalid-field")),
      city: yup
        .string()
        .trim()
        .max(FIELDS_MAX_LENGTH.CITY)
        .required(t("invalid-field")),
      phoneCode: yup.object({
        alphaCode: yup.string(),
        code: yup.string(),
      }),
      phone: yup
        .string()
        .matches(/^[\d ]{4,32}$/, {
          message: t("invalid-phone"),
          excludeEmptyString: false,
        })
        .required(t("invalid-field")),
      service: yup
        .string()
        .trim()
        .max(FIELDS_MAX_LENGTH.SERVICE)
        .required(t("invalid-field")),
      company: yup
        .string()
        .trim()
        .max(FIELDS_MAX_LENGTH.COMPANY)
        .required(t("invalid-field")),
      message: yup
        .string()
        .trim()
        .max(FIELDS_MAX_LENGTH.MESSAGE)
        .required(t("invalid-field")),
      isTermsAccepted: yup.boolean().required().oneOf([true]),
    });
  }, [t]);

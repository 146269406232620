import { useI18next } from "gatsby-plugin-react-i18next";
import { useTranslation } from "react-i18next";

export const useTemplateTranslation = () => {
  const { originalPath } = useI18next();
  const path = originalPath.replace("/", "");
  const { t } = useTranslation(`t_${path}`);

  return { t };
};

import React from "react";
import classNames from "classnames";

type Props = {
  title: string;
};

export const MenuTitle: React.FC<Props> = ({ title }) => (
  <h4 className={classNames("pb-6 text-medium text-primary-content")}>
    {title}
  </h4>
);

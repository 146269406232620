import { useEffect, useState } from "react";

export const useMediaQuery = (query: string) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);

    if (media.matches !== matches) {
      setMatches(media.matches);
    }

    const listener = () => {
      setMatches(media.matches);
    };

    if (typeof media.addEventListener === "function") {
      media.addEventListener("change", listener);
    } else {
      media.addListener(listener);
    }

    return () => {
      if (typeof media.removeEventListener === "function") {
        media.removeEventListener("change", listener);
      } else {
        media.removeListener(listener);
      }
    };
  }, [matches, query]);

  return matches;
};

export const useIsSMMediaQuery = () => useMediaQuery("(min-width: 480px)");
export const useIsMDMediaQuery = () => useMediaQuery("(min-width: 768px)");
export const useIsLGMediaQuery = () => useMediaQuery("(min-width: 1024px)");
export const useIsXLMediaQuery = () => useMediaQuery("(min-width: 1200px)");
export const useIsXXLMediaQuery = () => useMediaQuery("(min-width: 1440px)");

import { API_PATHS } from "@app/constants/api";
import { GenericFormData, GenericFormMeta } from "@app/types/forms";
import { TRUE, FALSE } from "./constants";

export const useSubscribe = () => {
  const sendSubscribeRequest = async (
    data: GenericFormData,
    meta: GenericFormMeta,
  ) => {
    try {
      const response = await fetch(
        `${API_PATHS.INTERNAL.CONTACT_GENERIC}/${meta.formId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            firstname: data.firstName,
            lastname: data.lastName,
            email: data.email,
            phoneCode: data.phoneCode.code,
            phone: data.phone,
            how_did_you_hear_about_us: data.howDidYouHear,
            i_confirm_i_am_a_swiss_tax_resident_: data.isSwissResidentConfirmed
              ? TRUE
              : FALSE,
            rt_action: meta.action,
            rt: meta.token,
            location: meta.location,
            title: meta.title,
            language: meta.language,
          }),
        },
      );

      if (!response.ok) {
        throw new Error("Internal error");
      }

      return response;
    } catch (error) {
      throw new Error("Internal error");
    }
  };

  return { sendSubscribeRequest };
};

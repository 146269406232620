import { graphql, useStaticQuery } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
import { useSiteMetadata } from "@app/hooks";

export const useStructuredDataBreadcrumb = () => {
  const { originalPath } = useI18next();
  const { siteUrl } = useSiteMetadata();
  const { allSitePage } = useStaticQuery(graphql`
    query {
      allSitePage {
        nodes {
          id
          path
          pageContext
        }
      }
    }
  `);

  const pathSegments = originalPath.split("/").filter(Boolean);
  const allSeoTitles = allSitePage.nodes.reduce((prev, curr) => {
    prev[curr.path] = curr.pageContext.seoTitle;

    return prev;
  }, {});

  const getListItem = (position: number, name: string, url: string) => ({
    "@type": "ListItem",
    name,
    position,
    item: url,
  });

  return {
    "@type": "BreadcrumbList",
    itemListElement: [
      getListItem(1, "Home", siteUrl),
      ...pathSegments.map((s, i) => {
        const path = "/" + pathSegments.slice(0, i + 1).join("/");
        const url = siteUrl + path;
        const name = allSeoTitles[path] || allSeoTitles[path + "/"];

        return getListItem(i + 2, name, url);
      }),
    ],
  };
};

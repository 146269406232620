import React from "react";
import classNames from "classnames";
import { Link } from "@app/components/common";

type Props = {
  items: {
    label: string;
    link: string;
  }[];
};

export const Menu: React.FC<Props> = ({ items }) => (
  <ul className={classNames("pb-15 text-neutral-content")}>
    {items.map((item) => (
      <li key={item.label}>
        {item.link.includes("http") ? (
          <a
            href={item.link}
            target="_blank"
            rel="noopener noreferrer"
            className={classNames(
              "text-small no-underline duration-300 hover:text-primary-content",
            )}
          >
            {item.label}
          </a>
        ) : (
          <Link
            to={item.link}
            className={classNames(
              "text-small no-underline duration-300 hover:text-primary-content",
            )}
          >
            {item.label}
          </Link>
        )}
      </li>
    ))}
  </ul>
);

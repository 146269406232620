import { API_PATHS } from "@app/constants/api";

export const useSMS = () => {
  const sendSMSRequest = async (
    phone: string,
    message: string,
    recaptchaToken: string,
    recaptchaAction: string,
  ) => {
    try {
      const response = await fetch(`${API_PATHS.INTERNAL.SMS}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          phone: phone,
          message: message,
          rt_action: recaptchaAction,
          rt: recaptchaToken,
        }),
      });

      if (!response.ok) {
        throw new Error("Internal error");
      }

      return response;
    } catch (error) {
      throw new Error("Internal error");
    }
  };

  return { sendSMSRequest };
};

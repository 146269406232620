export const getYoutubeThumbnail = (youtubeId: string) => {
  return `https://img.youtube.com/vi/${youtubeId}/maxresdefault.jpg`;
};

export const getYoutubeEmbedUrl = (youtubeId: string) => {
  return `https://www.youtube.com/embed/${youtubeId}?rel=0&modestbranding=1`;
};

export const getYoutubeUrl = (youtubeId: string) => {
  return `https://www.youtube.com/watch?v=${youtubeId}`;
};

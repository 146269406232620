import React, { FC } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Input, PhoneInput } from "@app/components/common/form";

type Props = {
  isDataLoading: boolean;
};

export const Details: FC<Props> = ({ isDataLoading }) => {
  const { t } = useTranslation("schedule-a-call-modal");

  return (
    <div>
      <h4
        className={classNames(
          "mb-5 text-regular",
          "xl:mb-10 xl:text-medium-standart",
        )}
      >
        {t("details")}
      </h4>
      <div
        className={classNames(
          "flex flex-col gap-y-5",
          "xl:grid xl:grid-cols-2 xl:gap-x-10",
        )}
      >
        <Input
          name="name"
          label={t("name")}
          placeholder={t("name-placeholder")}
          isDisabled={isDataLoading}
        />
        <Input
          name="lastName"
          label={t("lastName")}
          placeholder={t("lastName-placeholder")}
          isDisabled={isDataLoading}
        />
        <Input
          name="email"
          label={t("email")}
          placeholder={t("email-placeholder")}
          isDisabled={isDataLoading}
          type="email"
        />
        <PhoneInput
          phoneName="phone"
          codeName="phoneCode"
          label={t("phone")}
          placeholder={t("phone-placeholder")}
          isDisabled={isDataLoading}
          dropdownBgVariant="grey"
        />
      </div>
    </div>
  );
};

import { useStaticQuery, graphql } from "gatsby";

export const useQRCodeData = () => {
  const data = useStaticQuery(graphql`
    query QRCodeQuery {
      codes: allStoryblokDatasourceEntry(
        filter: { data_source: { eq: "qr-codes" }, name: { eq: "generic" } }
      ) {
        edges {
          node {
            value
          }
        }
      }
    }
  `);

  return data.codes.edges[0].node.value;
};

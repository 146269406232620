import { useMemo } from "react";
import { useStaticQuery, graphql } from "gatsby";

const ORDER_KEY_NAME = "order";
const DEFAULT_ORDER_VALUE = 99;

type MenuItem = {
  url: string;
  order: number;
  en?: string;
  de?: string;
  fr?: string;
  it?: string;
};

export const useMenuData = () => {
  const data = useStaticQuery(graphql`
    query MenuQuery {
      menu: allStoryblokDatasourceEntry(
        filter: { data_source: { eq: "main-menu" } }
      ) {
        group(field: { value: SELECT }) {
          edges {
            node {
              value
              optionKey: data_source_dimension
              option: dimension_value
            }
          }
        }
      }
    }
  `);

  const groupedData: MenuItem[] = useMemo(
    () =>
      data.menu.group
        .map((item) =>
          item.edges.reduce(
            (resultItem, edge) => ({
              ...resultItem,
              [edge.node.optionKey ?? "url"]: edge.node.optionKey
                ? edge.node.optionKey === ORDER_KEY_NAME
                  ? isNaN(parseInt(edge.node.option))
                    ? DEFAULT_ORDER_VALUE
                    : parseInt(edge.node.option)
                  : edge.node.option
                : edge.node.value,
            }),
            {},
          ),
        )
        .sort((a: MenuItem, b: MenuItem) => a.order - b.order),
    [data],
  );

  return groupedData;
};

import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="22px"
    height="22px"
    viewBox="0 0 22 22"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <g
      id="Desktop"
      stroke="none"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Homepage"
        transform="translate(-95, -29)"
        strokeWidth={1.5}
        stroke="#FFFFFF"
      >
        <g id="Group-7" transform="translate(96.435, 25)">
          <g id="Group-65" transform="translate(0, 5.4347)">
            <path
              d="M9.56521739,19.1304348 C12.2295652,19.1304348 14.6382609,18.0391304 16.3730435,16.2808696"
              id="Stroke-1"
            />
            <path
              d="M9.56521739,19.1304348 C6.90086957,19.1304348 4.49217391,18.0391304 2.7573913,16.2808696"
              id="Stroke-3"
            />
            <path
              d="M16.3726957,2.84991304 C18.0761739,4.57686957 19.130087,6.94730435 19.130087,9.56556522"
              id="Stroke-5"
            />
            <path
              d="M2.75773913,2.84991304 C4.49252174,1.09165217 6.90034783,0.000347826087 9.56556522,0.000347826087"
              id="Stroke-7"
            />
            <path
              d="M9.56521739,0 C12.2295652,0 14.6382609,1.09130435 16.3730435,2.84956522"
              id="Stroke-9"
            />
            <path
              d="M19.1304348,9.56521739 C19.1304348,12.1834783 18.0765217,14.5530435 16.3730435,16.2808696"
              id="Stroke-11"
            />
            <path
              d="M0,9.56521739 C0,12.1834783 1.05391304,14.5530435 2.7573913,16.2808696"
              id="Stroke-13"
            />
            <path
              d="M2.75773913,2.84991304 C1.05426087,4.57686957 0.000347826088,6.94730435 0.000347826088,9.56556522"
              id="Stroke-15"
            />
            <path
              d="M9.56521739,14.3478261 C10.9417391,14.3478261 12.2669565,14.5643478 13.513913,14.96"
              id="Stroke-17"
            />
            <path
              d="M5.61652174,14.9597391 C6.8626087,14.564087 8.18869565,14.3475652 9.56521739,14.3475652"
              id="Stroke-19"
            />
            <path
              d="M2.75773913,16.2806087 C3.64817391,15.7345217 4.60208696,15.2823478 5.61686957,14.9597391"
              id="Stroke-21"
            />
            <path
              d="M16.3726957,16.2806087 C15.4822609,15.7345217 14.5283478,15.2823478 13.5135652,14.9597391"
              id="Stroke-23"
            />
            <path
              d="M16.3726957,2.84991304 C15.4822609,3.39686957 14.5283478,3.84817391 13.5135652,4.17078261"
              id="Stroke-25"
            />
            <path
              d="M5.61652174,4.17078261 C6.8626087,4.56643478 8.18869565,4.78295652 9.56521739,4.78295652"
              id="Stroke-27"
            />
            <path
              d="M2.75773913,2.84991304 C3.64817391,3.39686957 4.60208696,3.84817391 5.61686957,4.17078261"
              id="Stroke-29"
            />
            <path
              d="M13.5134783,4.17078261 C12.2673913,4.56643478 10.9413043,4.78295652 9.56478261,4.78295652"
              id="Stroke-31"
            />
            <line
              x1={14.3478261}
              y1={9.56521739}
              x2={9.56521739}
              y2={9.56521739}
              id="Stroke-33"
            />
            <line
              x1={4.7826087}
              y1={9.56521739}
              x2={9.56521739}
              y2={9.56521739}
              id="Stroke-35"
            />
            <line
              x1={4.7826087}
              y1={9.56521739}
              x2={0}
              y2={9.56521739}
              id="Stroke-37"
            />
            <line
              x1={19.1304348}
              y1={9.56521739}
              x2={14.3478261}
              y2={9.56521739}
              id="Stroke-39"
            />
            <line
              x1={9.56521739}
              y1={0}
              x2={9.56521739}
              y2={4.7826087}
              id="Stroke-41"
            />
            <line
              x1={9.56521739}
              y1={19.1304348}
              x2={9.56521739}
              y2={14.3478261}
              id="Stroke-43"
            />
            <line
              x1={9.56521739}
              y1={14.3478261}
              x2={9.56521739}
              y2={9.56521739}
              id="Stroke-45"
            />
            <line
              x1={9.56521739}
              y1={4.7826087}
              x2={9.56521739}
              y2={9.56521739}
              id="Stroke-47"
            />
            <path
              d="M4.7826087,9.56521739 C4.7826087,7.56347826 5.09043478,5.70695652 5.61652174,4.17043478"
              id="Stroke-49"
            />
            <path
              d="M9.56521739,0 C11.2052174,0 12.6521739,1.65304348 13.513913,4.17043478"
              id="Stroke-51"
            />
            <path
              d="M13.5134783,4.17078261 C14.0386957,5.70730435 14.3473913,7.56295652 14.3473913,9.56556522"
              id="Stroke-53"
            />
            <path
              d="M14.3478261,9.56521739 C14.3478261,11.5669565 14.0391304,13.4234783 13.513913,14.96"
              id="Stroke-55"
            />
            <path
              d="M9.56521739,0 C7.92434783,0 6.47826087,1.65304348 5.61652174,4.17043478"
              id="Stroke-57"
            />
            <path
              d="M9.56521739,19.1304348 C11.2052174,19.1304348 12.6521739,17.4773913 13.513913,14.96"
              id="Stroke-59"
            />
            <path
              d="M9.56521739,19.1304348 C7.92434783,19.1304348 6.47826087,17.4773913 5.61652174,14.96"
              id="Stroke-61"
            />
            <path
              d="M4.7826087,9.56521739 C4.7826087,11.5669565 5.09043478,13.4234783 5.61652174,14.96"
              id="Stroke-63"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SvgComponent;

import { graphql, useStaticQuery } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";

export const useCookiesPolicy = () => {
  const { language } = useI18next();
  const { allFile } = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          sourceInstanceName: { eq: "pages" }
          relativeDirectory: { eq: "cookies-policy" }
        }
      ) {
        edges {
          node {
            name
            childMarkdownRemark {
              html
              frontmatter {
                necessary
                non_necessary
              }
            }
          }
        }
      }
    }
  `);

  const policy = allFile.edges
    .map((f) => f.node)
    .find((f) => f.name === language).childMarkdownRemark;

  const { necessary, non_necessary } = policy.frontmatter;

  return {
    html: policy.html,
    necessary,
    non_necessary,
  };
};

import { graphql, useStaticQuery } from "gatsby";

type StoryblokLinkNode = {
  node: {
    fullSlug: string;
    id: string;
    lang: string;
  };
};

export const useStoryblokLink = (id: string, language: string) => {
  const { entries } = useStaticQuery(graphql`
    {
      entries: allStoryblokEntry {
        edges {
          node {
            fullSlug: full_slug
            id: uuid
            lang
          }
        }
      }
    }
  `);

  try {
    return entries?.edges
      .filter(
        (entry: StoryblokLinkNode) =>
          entry.node.id === id && entry.node.lang === language,
      )
      .map((entry: StoryblokLinkNode) => entry.node)[0]?.fullSlug;
  } catch (e) {
    console.log("Storyblok links parsing error!");

    return undefined;
  }
};

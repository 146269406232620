import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import classNames from "classnames";
import { useSiteData } from "@app/hooks";

export const AppStoreLinks: React.FC = () => {
  const siteData = useSiteData();

  return (
    <div
      className={classNames(
        "align-center mb-10 flex flex-wrap gap-3",
        "lg:mb-15 lg:gap-x-5",
      )}
    >
      <a href={siteData.iosLink} target="_blank" rel="noopener noreferrer">
        <StaticImage
          className="w-[135px] md:w-[150px]"
          src="../../../../../images/app_store.png"
          alt="Apple Store"
          quality={100}
        />
      </a>

      <a href={siteData.androidLink} target="_blank" rel="noopener noreferrer">
        <StaticImage
          className="w-[153px] md:w-[170px]"
          src="../../../../../images/google_play.png"
          alt="Google Play"
          quality={100}
        />
      </a>
    </div>
  );
};

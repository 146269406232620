import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={40}
    height={40}
    viewBox="0 0 10.583333 10.583334"
    id="svg894"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <defs id="defs891" />
    <g id="layer1">
      <path
        style={{
          fill: "#000000",
          strokeWidth: 0.170866,
        }}
        id="path1207"
        d=""
      />
      <path
        id="rect1271"
        style={{
          fill: "#ffffff",
          strokeWidth: 0.264583,
        }}
        d="M 9.2002427,0.453181 5.2911128,4.3623109 1.3819827,0.45318087 0.42132612,1.4138374 4.3304562,5.3229675 0.42169165,9.231732 1.382348,10.192389 5.2911126,6.2836238 9.2002425,10.192754 10.160899,9.2320972 6.2517691,5.3229673 10.160899,1.4138373 Z"
      />
    </g>
  </svg>
);

export default SvgComponent;

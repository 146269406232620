import React from "react";
import { useTranslation } from "react-i18next";

export const Disclaimers: React.FC = () => {
  const { t } = useTranslation("footer");
  const disclaimers: string | string[] = t("disclaimer", {
    returnObjects: true,
  });

  if (typeof disclaimers !== "object") {
    return null;
  }

  return (
    <div className="space-y-4">
      {disclaimers.map((paragraph, i) => (
        <p key={i} className="text-extra-small text-neutral-content">
          {paragraph}
        </p>
      ))}
    </div>
  );
};

import { TFunction } from "react-i18next";
import * as yup from "yup";

export const useValidationSchema = (t: TFunction<"translation", string>) =>
  yup.object({
    phoneCode: yup.object().shape({
      alphaCode: yup.string(),
      code: yup.string(),
    }),
    phone: yup
      .string()
      .matches(/^(?=.*\d)[\d\s]{2,32}$/, {
        message: t("invalid-phone"),
        excludeEmptyString: false,
      })
      .required(t("invalid-field")),
  });

import React, { FC } from "react";
import classNames from "classnames";
import { useFormContext, useController } from "react-hook-form";
import { ChevronDown } from "@app/components/common/icons";
import { CountriesDataVariant } from "@app/enums/countries";
import { useCountriesData } from "@app/hooks";

const SHOW_DIVIDER_AFTER = 5;

type PhoneCode = {
  alphaCode: string;
  code: string;
};

type Props = {
  name: string;
  isDisabled?: boolean;
  dropdownBgVariant?: "grey" | "black";
  vAlign?: "top" | "bottom";
  countryListVariant?: CountriesDataVariant;
};

export const PhonecodeDropdown: FC<Props> = ({
  name,
  isDisabled,
  dropdownBgVariant,
  vAlign,
  countryListVariant,
}) => {
  const { control, formState } = useFormContext();
  const { field } = useController({
    name,
    control,
  });
  const countriesData = useCountriesData(countryListVariant);

  const selectedCountry =
    countriesData.filter(
      (country) => country.code === field.value?.alphaCode,
    )[0] ?? countriesData[0];

  const onChangeValues = (value: PhoneCode) => {
    if (!isDisabled) {
      field.onChange(value);
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
    }
  };

  const onKeyDown = (
    event: React.KeyboardEvent<HTMLElement>,
    value: PhoneCode,
  ) => {
    if (event.key === "Enter") {
      onChangeValues(value);
    }
  };

  return (
    <div
      className={classNames("dropdown dropdown-end w-32", {
        "dropdown-top": vAlign === "top",
        "dropdown-bottom": vAlign !== "top",
      })}
    >
      <button
        tabIndex={0}
        type="button"
        className={classNames(
          "flex w-32 items-center justify-between gap-2 px-4 py-2.5",
          "rounded-sm border border-divider text-regular text-base-400",
          {
            "group duration-300 focus:border-primary-content focus:shadow-md":
              !isDisabled || !formState.isSubmitting,
          },
          dropdownBgVariant === "grey"
            ? "[&:disabled]:bg-transparent"
            : "[&:disabled]:bg-dark",
          {
            "cursor-not-allowed opacity-50":
              isDisabled || formState.isSubmitting,
          },
        )}
        disabled={isDisabled || formState.isSubmitting}
        ref={field.ref}
        onBlur={field.onBlur}
      >
        <div className="flex items-center gap-x-2">
          <img
            src={selectedCountry.iconPath}
            alt={selectedCountry.code}
            className="h-5 w-5"
          />
          <span className="truncate">+{field.value?.code}</span>
        </div>
        <ChevronDown
          className={classNames(
            "h-3 w-3",
            isDisabled || formState.isSubmitting
              ? "fill-base-400"
              : "fill-primary-content duration-300 group-focus:-scale-100",
          )}
        />
      </button>
      <div
        tabIndex={0}
        className={classNames(
          "menu dropdown-content z-10 w-full space-y-3",
          "rounded-sm border border-divider text-regular text-base-400",
          dropdownBgVariant === "grey" ? "bg-dark" : "bg-base-100",
          vAlign === "top" ? "mb-2.5" : "mt-2.5",
          { hidden: isDisabled || formState.isSubmitting },
        )}
      >
        <div
          className="max-h-52 cursor-pointer overflow-y-scroll scrollbar-thin"
          tabIndex={-1}
        >
          {countriesData.map((country, i) => (
            <div
              key={`${country.phoneCode} (${country.code})`}
              tabIndex={0}
              onClick={() =>
                onChangeValues({
                  alphaCode: country.code,
                  code: country.phoneCode,
                })
              }
              onKeyDown={(event) =>
                onKeyDown(event, {
                  alphaCode: country.code,
                  code: country.phoneCode,
                })
              }
              className={classNames(
                "flex items-center gap-x-2 px-2 py-1.5 duration-300 hover:text-primary-content",
                {
                  "mb-1 border-b border-divider pb-3":
                    countryListVariant !==
                      CountriesDataVariant.SWITZERLAND_AND_NEIGHBORS &&
                    i === SHOW_DIVIDER_AFTER,
                },
              )}
            >
              <img
                src={country.iconPath}
                alt={country.code}
                className="h-5 w-5"
              />
              <span className="truncate">+{country.phoneCode}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

import React from "react";
import classNames from "classnames";
import { AppStoreLinks } from "./components/app-store-links/AppStoreLinks";
import { Navigation } from "./components/navigation/Navigation";
import { TermsAndSocialMedia } from "./components/terms-and-social-media/TermsAndSocialMedia";
import { Disclaimers } from "./components/disclaimers/Disclaimers";

export const Footer: React.FC = () => (
  <footer
    className={classNames(
      "flex justify-center bg-dark px-4.5 pb-[50px] pt-10",
      "lg:px-16 lg:pb-20 lg:pt-20",
      "xl:px-24",
    )}
  >
    <div className="max-w-container">
      <AppStoreLinks />
      <Navigation />
      <TermsAndSocialMedia />
      <Disclaimers />
    </div>
  </footer>
);

import React, { FC } from "react";
import classNames from "classnames";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonVariant } from "@app/components/common/enums";
import { CustomTrackingEvent } from "@app/constants/tracking";

type Props = {
  label: string;
  variant?: ButtonVariant;
  isFullWidth?: boolean;
  labelStyles?: string;
  trackEvent?: CustomTrackingEvent;
  onClick?: () => void;
};

export const Button: FC<Props> = ({
  label,
  variant,
  isFullWidth,
  labelStyles,
  trackEvent,
  onClick,
}) => {
  const handleClick = () => {
    if (trackEvent && window) {
      window.dataLayer.push({ event: trackEvent });
    }

    if (onClick) {
      onClick();
    }
  };

  return (
    <button
      className={classNames(
        "btn no-animation h-auto min-h-0 flex-nowrap font-normal duration-300",
        { "border-0": variant !== ButtonVariant.OUTLINE },
        {
          "btn-link gap-[10px] px-0 py-0 text-regular-low text-primary-content no-underline hover:text-base-300 hover:no-underline":
            variant !== ButtonVariant.OUTLINE &&
            variant !== ButtonVariant.FILL_PRIMARY_CONTENT &&
            variant !== ButtonVariant.FILL_INFO,
        },
        {
          "btn-outline justify-between rounded-sm border border-divider py-2 pl-[18px] pr-5 text-small text-neutral-content hover:border-primary-content hover:bg-transparent hover:text-neutral-content":
            variant === ButtonVariant.OUTLINE,
        },
        {
          "px-[26px] py-2 text-extra-small-low md:px-[30px] md:text-small-low":
            variant === ButtonVariant.FILL_PRIMARY_CONTENT ||
            variant === ButtonVariant.FILL_INFO,
        },
        { "btn-info": variant === ButtonVariant.FILL_INFO },
        { "w-full": isFullWidth },
      )}
      onClick={handleClick}
    >
      <span className={labelStyles}>{label}</span>
      {variant !== ButtonVariant.FILL_PRIMARY_CONTENT &&
        variant !== ButtonVariant.FILL_INFO && (
          <FontAwesomeIcon
            icon={faChevronRight}
            width={8}
            className={classNames({
              "text-primary-content": variant === ButtonVariant.OUTLINE,
            })}
          />
        )}
    </button>
  );
};

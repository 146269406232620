import { useI18next } from "gatsby-plugin-react-i18next";

const LOCALE_TO_DATE_FORMAT_MAP = {
  en: "en-ch",
  de: "de",
  it: "it",
  fr: "fr",
};

export const useFormatDate = (date: string) => {
  const [dateString] = date.split(" ");
  const [year, month, day] = dateString
    .split("-")
    .map((i) => parseInt(`${i}`, 10));

  const _date = new Date(year, month - 1, day);
  const { language } = useI18next();
  const locale = LOCALE_TO_DATE_FORMAT_MAP[language] ?? language;

  const formatted = new Intl.DateTimeFormat(locale, {
    day: "numeric",
    month: "long",
    year: "numeric",
  }).format(_date);

  return formatted;
};

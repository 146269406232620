import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <defs>
      <polygon id="path-1" points="0 0 20 0 20 20 0 20" />
    </defs>
    <g id="Mobile" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <g id="Homepage" transform="translate(-175, -7299)">
        <g id="Footer-24-Copy" transform="translate(0, 6570)">
          <g id="Group-6" transform="translate(18, 728.9998)">
            <g id="Group-3" transform="translate(157, 0)">
              <mask id="mask-2" fill="white">
                <use xlinkHref="#path-1" />
              </mask>
              <g id="Clip-2" />
              <path
                d="M10.0000696,6.66648109 C11.8372431,6.66648109 13.3334261,8.1626745 13.3334261,9.99986082 C13.3334261,11.8384389 11.8372431,13.3332405 10.0000696,13.3332405 C8.16289605,13.3332405 6.66671306,11.8384389 6.66671306,9.99986082 C6.66671306,8.1626745 8.16289605,6.66648109 10.0000696,6.66648109 L10.0000696,6.66648109 Z M10.0000696,4.44376401 C6.9367219,4.44376401 4.44401144,6.93649181 4.44401144,9.99986082 C4.44401144,13.0632298 6.9367219,15.5559576 10.0000696,15.5559576 C13.0634173,15.5559576 15.5561277,13.0632298 15.5561277,9.99986082 C15.5561277,6.93649181 13.0634173,4.44376401 10.0000696,4.44376401 L10.0000696,4.44376401 Z M15.5561277,3.33310137 C14.9423448,3.33310137 14.444081,3.82997676 14.444081,4.44376401 C14.444081,5.05755125 14.9423448,5.55581845 15.5561277,5.55581845 C16.1699107,5.55581845 16.6667826,5.05755125 16.6667826,4.44376401 C16.6667826,3.82997676 16.1699107,3.33310137 15.5561277,3.33310137 L15.5561277,3.33310137 Z M5.55605815,2.22243873 L14.444081,2.22243873 C16.2826464,2.22243873 17.7774376,3.71724032 17.7774376,5.55581845 L17.7774376,14.4439032 C17.7774376,16.2824813 16.2826464,17.7772829 14.444081,17.7772829 L5.55605815,17.7772829 C3.71749281,17.7772829 2.22270162,16.2824813 2.22270162,14.4439032 L2.22270162,5.55581845 C2.22270162,3.71724032 3.71749281,2.22243873 5.55605815,2.22243873 L5.55605815,2.22243873 Z M5.55605815,-0.000278361564 C2.49271046,-0.000278361564 0,2.49244944 0,5.55581845 L0,14.4439032 C0,17.5072722 2.49271046,20 5.55605815,20 L14.444081,20 C17.5074287,20 20.0001392,17.5072722 20.0001392,14.4439032 L20.0001392,5.55581845 C20.0001392,2.49244944 17.5074287,-0.000278361564 14.444081,-0.000278361564 L5.55605815,-0.000278361564 Z"
                id="Fill-1"
                fill="#FFFFFF"
                mask="url(#mask-2)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SvgComponent;

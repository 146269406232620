import React, { FC, useRef } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Close } from "@app/components/common/icons";
import { Dialog } from "@app/constants/ui";
import { ModalState } from "@app/enums/modal";
import { useOutsideClick, useModal } from "@app/hooks";

export const SubscribeSuccess: FC = () => {
  const { t } = useTranslation("subscribe-success-modal");
  const { onChangeModalState } = useModal();
  const ref = useRef<HTMLDivElement>(null);

  const onClose = () => {
    onChangeModalState(ModalState.IDLE);
  };

  useOutsideClick(ref, onClose);

  return (
    <dialog id={Dialog.SUBSCRIBE_SUCCESS} className="modal modal-open z-[1000]">
      <div
        className={classNames(
          "modal-box relative flex flex-col items-center rounded-lg bg-dark px-5 pb-10 pt-15 text-center text-primary-content",
          "md:px-[70px] md:pb-22 md:pt-22",
          "xl:w-full xl:max-w-[600px]",
        )}
        ref={ref}
      >
        <h3 className={classNames("mb-10 text-large-medium")}>{t("title")}</h3>
        <p className="text-regular text-base-400">{t("paragraph")}</p>
        <Close
          className={classNames(
            "absolute right-5 top-5 h-5 w-5 cursor-pointer duration-300",
            "lg:right-6 lg:top-6 lg:h-6 lg:w-6",
            "hover:opacity-75",
          )}
          onClick={onClose}
        />
      </div>
    </dialog>
  );
};

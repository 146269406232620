import React, { FC } from "react";
import { Input } from "../input/Input";
import { PhonecodeDropdown } from "./components/phonecode-dropdown/PhonecodeDropdown";

type Props = {
  codeName: string;
  phoneName: string;
  label?: string;
  placeholder?: string;
  isDisabled?: boolean;
  dropdownBgVariant?: "grey" | "black";
};

export const PhoneInput: FC<Props> = ({
  codeName,
  phoneName,
  label,
  placeholder,
  isDisabled,
  dropdownBgVariant,
}) => {
  return (
    <div className="w-full">
      {label ? (
        <label className="flex pb-2.5 pt-0 text-regular text-base-400">
          {label}
        </label>
      ) : null}
      <div className="flex gap-x-5">
        <PhonecodeDropdown
          name={codeName}
          dropdownBgVariant={dropdownBgVariant}
          isDisabled={isDisabled}
        />
        <Input
          name={phoneName}
          type="tel"
          placeholder={placeholder}
          isDisabled={isDisabled}
        />
      </div>
    </div>
  );
};

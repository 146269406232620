import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width="40" height="40" viewBox="0 0 40 40" {...props}>
    <defs id="defs2" />
    <g id="layer1">
      <rect
        style={{
          fill: "#ffffff",
          strokeWidth: 0.264583,
        }}
        id="rect829"
        width={23.018427}
        height={18.427116}
        x={8.5245867}
        y={11.04657}
      />
      <path
        style={{
          fill: "#181A1C",
          strokeWidth: 0.227826,
        }}
        d="M 16.73032,39.056375 C 9.1444,37.569268 3.3793553,32.148649 1.4446037,24.683885 c -0.56890217,-2.19497 -0.56890217,-6.690249 0,-8.885219 C 2.9520068,9.9827209 6.7156594,5.4413429 12.095535,2.9468146 14.973291,1.6124635 16.71018,1.2387367 20.033799,1.2387367 c 3.323619,0 5.060508,0.3737268 7.938264,1.7080779 5.379877,2.4945283 9.143528,7.0359063 10.650933,12.8518514 0.568902,2.19497 0.568902,6.690249 0,8.885219 -1.171218,4.518856 -4.083565,8.760103 -7.727974,11.254236 -1.51816,1.038988 -4.529878,2.402484 -6.304701,2.854327 -2.053768,0.522861 -5.883212,0.651446 -7.860001,0.263927 z m 6.143335,-17.895819 5.456295,-5.459852 -0.54257,-0.577541 c -0.298416,-0.317647 -0.651958,-0.577541 -0.785654,-0.577541 -0.133695,0 -2.370626,2.125736 -4.970958,4.723857 l -4.727878,4.723856 -1.890728,-1.880906 -1.890728,-1.880906 -0.664606,0.693698 -0.664605,0.693699 2.492989,2.500744 c 1.371145,1.375407 2.5468,2.500743 2.612568,2.500743 0.06577,0 2.574912,-2.456934 5.575875,-5.459851 z"
        id="path182"
      />
    </g>
  </svg>
);

export default SvgComponent;

import { API_PATHS } from "@app/constants/api";
import { ComplaintFormData } from "@app/types/forms";

export const useComplaint = () => {
  const sendComplaintRequest = async (
    data: ComplaintFormData,
    recaptchaToken: string,
    recaptchaAction: string,
  ) => {
    try {
      const response = await fetch(`${API_PATHS.INTERNAL.COMPLAINT}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          firstName: data.firstName,
          lastName: data.lastName,
          address: data.address,
          email: data.email,
          postalCode: data.postalCode,
          city: data.city,
          phone: `${data.phoneCode.code}${data.phone}`,
          service: data.service,
          company: data.company,
          message: data.message,
          location: window?.location?.href,
          rt: recaptchaToken,
          rt_action: recaptchaAction,
        }),
      });

      if (!response.ok) {
        throw new Error("Internal error");
      }

      return response;
    } catch (error) {
      throw new Error("Internal error");
    }
  };

  return { sendComplaintRequest };
};

import { ModalState } from "@app/enums/modal";
import { ScheduleACall } from "./components/schedule-a-call/ScheduleACall";
import { ScheduleACallSuccess } from "./components/schedule-a-call-success/ScheduleACallSuccess";
import { Subscribe } from "./components/subscribe/Subscribe";
import { SubscribeSuccess } from "./components/subscribe-success/SubscribeSuccess";
import { DownloadApp } from "./components/download-app/DownloadApp";
import { Error } from "./components/error/Error";
import { LostCardSuccess } from "./components/lost-card-success/LostCardSuccess";
import { Success } from "./components/success/Success";
import { SMSSuccess } from "./components/sms-success/SMSSuccess";
import { FinancialReportSuccess } from "./components/financial-report-success/FinancialReportSuccess";
import { Complaint } from "./components/complaint/Complaint";
import { SimpleContactSuccess } from "./components/simple-contact-success/SimpleContactSuccess";

export const MODALS_MAP: Record<ModalState, React.FC | null> = {
  [ModalState.FINANCIAL_REPORT_SUCCESS]: FinancialReportSuccess,
  [ModalState.SUBSCRIBE]: Subscribe,
  [ModalState.SUBSCRIBE_SUCCESS]: SubscribeSuccess,
  [ModalState.SCHEDULE_A_CALL]: ScheduleACall,
  [ModalState.SCHEDULE_A_CALL_SUCCESS]: ScheduleACallSuccess,
  [ModalState.DOWNLOAD_APP]: DownloadApp,
  [ModalState.ERROR]: Error,
  [ModalState.LOST_CARD_SUCCESS]: LostCardSuccess,
  [ModalState.SUCCESS]: Success,
  [ModalState.SMS_SUCCESS]: SMSSuccess,
  [ModalState.COMPLAINT]: Complaint,
  [ModalState.SIMPLE_CONTACT_SUCCESS]: SimpleContactSuccess,
  [ModalState.IDLE]: null,
};

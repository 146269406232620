import { useState, useEffect } from "react";

export const useTransform = (
  inputValue: number,
  inputRange: [number, number],
  outputRange: [number, number],
): number => {
  const [outputValue, setOutputValue] = useState<number>(inputRange[0]);

  useEffect(() => {
    const inputDifference = inputRange[1] - inputRange[0];
    const outputDifference = outputRange[1] - outputRange[0];

    const scale = (inputValue - inputRange[0]) / inputDifference;
    let output = outputRange[0] + scale * outputDifference;

    if (outputDifference > 0) {
      output = Math.min(outputRange[1], Math.max(outputRange[0], output));
    } else {
      output = Math.min(outputRange[0], Math.max(outputRange[1], output));
    }
    setOutputValue(output);
  }, [inputValue, inputRange, outputRange]);

  return outputValue;
};

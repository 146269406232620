import dayjs, { Dayjs } from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(timezone);

type FromDate = Date | Dayjs | number | string;

export const formatToISOString = (from: FromDate) => dayjs(from).format();

export const formatToDateTimeString = (from: FromDate) =>
  dayjs(from).format("YYYY-MM-DDTHH:mm:ss");

export const formatToShortLocalTimeString = (from: FromDate) =>
  dayjs(from).local().format("HH:mm");

export const formatToShortTimeString = (from: FromDate) =>
  dayjs(from).format("hh:mm A");

export const formatToICSDateString = (from: FromDate) =>
  dayjs(from).format("YYYY-MM-DD-HH-mm");

export const formatToHubspotTimestamp = (from: FromDate) =>
  dayjs(from).format("YYYY-MM-DD").valueOf();

export const formatToSwissTime = (from: FromDate) =>
  dayjs.utc(from).tz("Europe/Zurich").format("HH:mm");

export const formatToSwissDate = (from: FromDate) =>
  dayjs.utc(from).tz("Europe/Zurich").format("DD.MM.YYYY");

// 02.02.24, 13:37
export const formatToShortDateTime = (from: FromDate) =>
  dayjs(from).format("DD.MM.YYYY, HH:mm");

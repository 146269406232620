"use client";

import React, {
  createContext,
  FC,
  PropsWithChildren,
  useEffect,
  useState,
} from "react";
import Cookies from "js-cookie";

const COOKIE_NAME = "cookies_seen";

const setSeenCookie = () => Cookies.set(COOKIE_NAME, "true", { expires: 365 });
const getSeenCookie = () => Cookies.get(COOKIE_NAME);

export const CookieConsentContext = createContext(
  {} as {
    cookieBannerIsOpen: boolean;
    closeCookieBanner: () => void;
  },
);

export const CookieConsentProvider: FC<PropsWithChildren> = ({ children }) => {
  const [cookieBannerIsOpen, setCookieBannerIsOpen] = useState(false);

  const handleCloseCookieBanner = () => {
    setSeenCookie();
    setCookieBannerIsOpen((s) => !s);
  };

  useEffect(() => {
    if (getSeenCookie() !== "true") {
      setCookieBannerIsOpen(true);
    }
  }, []);

  return (
    <CookieConsentContext.Provider
      value={{
        cookieBannerIsOpen,
        closeCookieBanner: handleCloseCookieBanner,
      }}
    >
      {children}
    </CookieConsentContext.Provider>
  );
};

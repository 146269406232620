import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 52 52" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M 15.817673,49.13053 A 5.2794871,-4.3767514 0 0 1 15.595942,49.11711 5.2794871,-4.3767514 0 0 1 10.536193,44.749466 5.2794871,-4.3767514 0 0 1 15.595942,40.38854 V 40.33477 H 33.187471 L 4.3811737,11.528471 l 0.020161,-0.0203 A 5.4935457,4.3844616 45 0 1 3.7159525,4.6274527 5.4935457,4.3844616 45 0 1 10.69746,5.4136307 5.4935457,4.3844616 45 0 1 10.939352,5.6689737 L 40.283207,35.012829 V 16.117724 a 4.3767514,-5.2794871 0 0 1 -0.01342,-0.248635 4.3767514,-5.2794871 0 0 1 0.01342,-0.221732 4.3767514,-5.2794871 0 0 1 4.360925,-5.059748 4.3767514,-5.2794871 0 0 1 4.367644,5.059748 h 0.05377 v 24.687276 8.782325 H 16.193983 a 5.2794871,-4.3767514 0 0 1 -0.37629,0.01342 z"
      style={{
        fill: "#b82828",
        fillOpacity: 1,
        strokeWidth: 3.53036,
      }}
    />
  </svg>
);

export default SvgComponent;

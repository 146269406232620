export const FORM_IDS = {
  en: "aec7d4a2-f0d3-4c6b-b6b1-1c91cb02fabd",
  de: "5cb3e28f-38ec-4e22-9240-9cd4ef7adf4c",
  it: "ddb65d8b-f87d-418e-8381-5b95e2036245",
  fr: "9fcde842-042e-4a56-869f-86f4ffe25e18",
};
export const UNDEFINED_VALUE = "undefined";
export const RECAPTCHA_ACTION = "subscribe";
export const TRUE = "yes";
export const FALSE = "no";

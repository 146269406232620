import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { YouTube, X, Instagram, LinkedIn } from "@app/components/common/icons";
import { Link } from "@app/components/common";
import { useSiteData } from "@app/hooks";

export const TermsAndSocialMedia: React.FC = () => {
  const { t } = useTranslation("footer");
  const siteData = useSiteData();

  const socialLinks = [
    {
      icon: <YouTube />,
      link: siteData.youtubeLink,
      label: "Alpian on YouTube",
    },
    {
      icon: <LinkedIn />,
      link: siteData.linkedInLink,
      label: "Alpian on LinkedIn",
    },
    {
      icon: <X />,
      link: siteData.twitterLink,
      label: "Alpian on X",
    },
    {
      icon: <Instagram />,
      link: siteData.instagramLink,
      label: "Alpian on Instagram",
    },
  ];

  const pageLinks = [
    {
      to: "/legal",
      label: t("legal"),
    },
    {
      to: "/legal/terms-of-use",
      label: t("terms_of_use"),
    },
    {
      to: "/legal/privacy-policy",
      label: t("privacy_policy"),
    },
    {
      to: "/contact/ombudsman",
      label: t("ombudsman"),
    },
  ];

  return (
    <div
      className={classNames(
        "mb-5 flex flex-col gap-5",
        "lg:mb-8 lg:flex-row lg:flex-wrap lg:gap-7.5",
      )}
    >
      <div
        className={classNames(
          "flex flex-wrap gap-x-7.5 gap-y-2.5 text-extra-small text-base-400",
          "md:text-extra-small-tall",
          "lg:order-2 lg:grow",
        )}
      >
        {pageLinks.map((link) => (
          <Link
            key={link.label}
            to={link.to}
            className="no-underline duration-300 hover:text-primary-content"
          >
            {link.label}
          </Link>
        ))}
      </div>
      <div
        className={classNames(
          "flex items-center gap-x-7.5",
          "lg:order-3 xl:grow xl:justify-end",
        )}
      >
        {socialLinks.map((link, i) => (
          <a
            key={`social-link-${i}`}
            href={link.link}
            target="_blank"
            rel="noopener noreferrer"
            className="cursor-pointer"
            aria-label={link.label}
          >
            {link.icon}
          </a>
        ))}
      </div>
      <div
        className={classNames(
          "text-extra-small-tall text-base-400",
          "lg:order-1",
        )}
      >
        © {new Date().getFullYear()} - Alpian SA
      </div>
    </div>
  );
};

import React, { FC } from "react";
import classNames from "classnames";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CustomTrackingEvent } from "@app/constants/tracking";
import { ButtonVariant } from "../enums";
import { Spinner } from "../icons";

type Props = {
  label: string;
  variant?: ButtonVariant;
  type?: "button" | "submit" | "reset";
  isFullWidth?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  trackEvent?: CustomTrackingEvent;
  onClick?: () => void;
};

export const Button: FC<Props> = ({
  label,
  variant,
  type = "button",
  isFullWidth,
  isDisabled,
  isLoading,
  trackEvent,
  onClick,
}) => {
  const handleClick = () => {
    if (trackEvent && window) {
      window.dataLayer.push({ event: trackEvent });
    }

    if (onClick) {
      onClick();
    }
  };

  return (
    <button
      className={classNames(
        "btn no-animation h-auto min-h-0 duration-300",
        { "border-0": variant !== ButtonVariant.OUTLINE },
        {
          "btn-link gap-[10px] px-0 py-0 text-regular-low text-primary-content no-underline hover:text-base-300 hover:no-underline":
            variant !== ButtonVariant.OUTLINE &&
            variant !== ButtonVariant.FILL_PRIMARY_CONTENT &&
            variant !== ButtonVariant.FILL_INFO,
        },
        {
          "btn-outline justify-between rounded-sm border border-divider py-2 pl-[18px] pr-5 text-small text-neutral-content hover:border-primary-content hover:bg-transparent hover:text-neutral-content":
            variant === ButtonVariant.OUTLINE,
        },
        {
          "px-[26px] py-2 text-extra-small-low md:px-[30px] md:text-small-low":
            variant === ButtonVariant.FILL_PRIMARY_CONTENT ||
            variant === ButtonVariant.FILL_INFO,
        },
        { "btn-info": variant === ButtonVariant.FILL_INFO },
        { "w-full": isFullWidth },
        { "cursor-not-allowed": isDisabled || isLoading },
      )}
      disabled={isDisabled || isLoading}
      type={type}
      onClick={handleClick}
    >
      <span className="font-normal">{label}</span>
      {variant !== ButtonVariant.FILL_PRIMARY_CONTENT &&
      variant !== ButtonVariant.FILL_INFO &&
      !isLoading ? (
        <FontAwesomeIcon
          icon={faChevronRight}
          width={8}
          className={classNames({
            "text-primary-content": variant === ButtonVariant.OUTLINE,
          })}
        />
      ) : null}
      {isLoading ? (
        <Spinner className="h-4 w-4 stroke-primary-content" />
      ) : null}
    </button>
  );
};

import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20px"
    height="19px"
    viewBox="0 0 20 19"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <g id="Mobile" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <g
        id="Homepage"
        transform="translate(-75, -7299)"
        fill="#FFFFFF"
        fillRule="nonzero"
      >
        <g id="Footer-24-Copy" transform="translate(0, 6570)">
          <g id="Group-6" transform="translate(18, 728.9998)">
            <g id="001-linkedin-copy" transform="translate(57, 0)">
              <path
                d="M2.41397185,0 C0.95433837,0 0,1.02631329 0,2.37526021 C0,3.69441555 0.925908669,4.75 2.35796294,4.75 L2.38566366,4.75 C3.87384833,4.75 4.8,3.69441555 4.8,2.37526021 C4.77217779,1.02631329 3.87384833,0 2.41397185,0 Z"
                id="Path"
              />
              <polygon
                id="Path"
                points="0 6.33333333 4.8 6.33333333 4.8 19 0 19"
              />
              <path
                d="M15.172465,5.54166667 C12.9103266,5.54166667 11.393439,7.75730263 11.393439,7.75730263 L11.393439,5.85029266 L7.2,5.85029266 L7.2,19 L11.3932002,19 L11.3932002,11.6566925 C11.3932002,11.2635677 11.4205412,10.8710651 11.5313378,10.5899417 C11.8344766,9.80493653 12.5243291,8.99168217 13.682799,8.99168217 C15.2001642,8.99168217 15.8070386,10.1975636 15.8070386,11.9653185 L15.8070386,19 L20,19 L20,11.4601923 C20,7.42117409 17.9311588,5.54166667 15.172465,5.54166667 Z"
                id="Path"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SvgComponent;

"use client";

import React, { createContext, useState, useEffect } from "react";
import { OsNames } from "@app/constants";
import { getUserOSName } from "@app/utils";

const APP_DOWNLOAD_COOKIE_NAME = "app_download";
const osName = getUserOSName();

interface DownloadAppTopbarInterface {
  isVisible: boolean;
  onHide: () => void;
}

export const DownloadAppTopbarContext = createContext(
  {} as DownloadAppTopbarInterface,
);

export const DownloadAppTopbarProvider = ({
  children,
}: React.PropsWithChildren) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const appDownloadCookie = sessionStorage.getItem(APP_DOWNLOAD_COOKIE_NAME);

    if (
      !appDownloadCookie &&
      (osName === OsNames.IOs || osName === OsNames.Android)
    ) {
      setIsVisible(true);
    }
  }, []);

  return (
    <DownloadAppTopbarContext.Provider
      value={{
        isVisible: isVisible,
        onHide: () => {
          sessionStorage.setItem(
            APP_DOWNLOAD_COOKIE_NAME,
            APP_DOWNLOAD_COOKIE_NAME,
          );
          setIsVisible(false);
        },
      }}
    >
      {children}
    </DownloadAppTopbarContext.Provider>
  );
};

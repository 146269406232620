import React, { FC } from "react";
import { useI18next } from "gatsby-plugin-react-i18next";
import { Helmet } from "react-helmet";
import {
  useStructuredDataBreadcrumb,
  useSeoTranslation,
  useSiteData,
  useSiteMetadata,
} from "@app/hooks";
import { getCurrentUrl } from "@app/utils";

type MetaItem = { name?: string; property?: string; content: string };

type Props = {
  title?: string;
  description?: string;
  keywords?: string;
  isArticle?: boolean;
  image?: string;
  meta?: MetaItem[];
};

export const SEO: FC<Props> = ({
  title,
  description,
  keywords,
  isArticle,
  image,
  meta = [],
}) => {
  const { language, languages, path } = useI18next();
  const { siteUrl } = useSiteMetadata();
  const {
    twitterLink,
    instagramLink,
    youtubeLink,
    linkedInLink,
    twitterHandle,
  } = useSiteData();
  const { t: seoT } = useSeoTranslation();
  const schemaOrgBreadcrumb = useStructuredDataBreadcrumb();

  const currentUrl = getCurrentUrl(siteUrl, path);
  let featuredImageUrl =
    image || new URL("/images/alpian-share-img.jpg", siteUrl).href;

  featuredImageUrl = featuredImageUrl.startsWith("http")
    ? featuredImageUrl
    : new URL(featuredImageUrl, siteUrl).href;
  const logoUrl = siteUrl + "/images/alpian_logo-black.png";
  const logoAltUrl = siteUrl + "/images/logo.png";
  const metaTitle = title || seoT("title");
  const metaDescription = description || seoT("description");
  const metaKeywords = keywords || seoT("keywords");

  const getOpenGraph = (): MetaItem[] => {
    const result: MetaItem[] = [
      { property: "og:type", content: isArticle ? "article" : "website" },
      { property: "og:title", content: metaTitle },
      { property: "og:description", content: metaDescription },
      { property: "og:url", content: currentUrl },
      { property: "og:site_name", content: "Alpian" },
      { property: "og:image", name: "image", content: featuredImageUrl },
      { property: "og:image:width", content: "1280" },
      { property: "og:image:height", content: "853" },
      { property: "og:locale", content: toFullLocale(language) },
    ];

    languages
      .filter((l) => l !== language)
      .forEach((l) =>
        result.push({
          property: "og:locale:alternate",
          content: toFullLocale(l),
        }),
      );

    return result;
  };

  const getTwitterCard = (): MetaItem[] => {
    const result: MetaItem[] = [
      { name: "twitter:card", content: "summary_large_image" },
      { name: "twitter:site", content: twitterHandle },
      { name: "twitter:title", content: metaTitle },
      { name: "twitter:description", content: metaDescription },
      { name: "twitter:image", content: featuredImageUrl },
    ];

    return result;
  };

  const metaItems: MetaItem[] = [
    { name: "description", content: metaDescription },
    { name: "keywords", content: metaKeywords },
    ...getOpenGraph(),
    ...getTwitterCard(),
    ...meta,
  ];

  const schemaOrgOrganization = {
    "@type": "Organization",
    "@id": `${siteUrl}/#organization`,
    name: "Alpian SA",
    url: siteUrl,
    sameAs: [linkedInLink, instagramLink, youtubeLink, twitterLink],
    logo: {
      "@type": "ImageObject",
      "@id": `${siteUrl}/#logo`,
      inLanguage: language,
      url: logoAltUrl,
      contentUrl: logoAltUrl,
      width: 512,
      height: 512,
      caption: "Alpian SA",
    },
    image: { "@id": `${siteUrl}/#logo` },
  };

  const schemaOrgWebsite = {
    "@type": "WebSite",
    "@id": `${siteUrl}/#website`,
    url: siteUrl,
    name: "Alpian",
    description: "An innovative Swiss financial services company",
    publisher: { "@id": `${siteUrl}/#organization` },
    inLanguage: language,
  };

  const schemaOrgPrimaryImage = {
    "@type": "ImageObject",
    "@id": `${currentUrl}#primaryimage`,
    inLanguage: language,
    url: logoUrl,
    contentUrl: logoUrl,
    width: 110,
    height: 36,
  };

  const schemaOrgWebpage = {
    "@type": "WebPage",
    "@id": `${currentUrl}#webpage`,
    url: currentUrl,
    name: metaTitle,
    isPartOf: { "@id": `${siteUrl}/#website` },
    primaryImageOfPage: {
      "@id": `${currentUrl}#primaryimage`,
    },
    description: metaDescription,
    inLanguage: language,
    potentialAction: [{ "@type": "ReadAction", target: [currentUrl] }],
  };

  const schemaOrgArticle = {
    "@type": "Article",
    description: metaDescription,
    headline: metaTitle,
    inLanguage: language,
    url: currentUrl,
    name: metaTitle,
    image: {
      "@type": "ImageObject",
      url: featuredImageUrl,
    },
    mainEntityOfPage: currentUrl,
    author: {
      "@type": "Organization",
      name: "Alpian SA",
    },
    copyrightHolder: {
      "@type": "Organization",
      name: "Alpian SA",
    },
    creator: {
      "@type": "Organization",
      name: "Alpian SA",
    },
    publisher: {
      "@type": "Organization",
      name: "Alpian SA",
      logo: {
        "@type": "ImageObject",
        url: logoUrl,
      },
    },
  };

  const schemas = {
    "@context": "https://schema.org",
    "@graph": [
      schemaOrgOrganization,
      schemaOrgWebsite,
      schemaOrgPrimaryImage,
      schemaOrgBreadcrumb,
      isArticle ? schemaOrgArticle : schemaOrgWebpage,
    ],
  };

  return (
    <Helmet
      title={metaTitle}
      meta={metaItems}
      htmlAttributes={{
        lang: language,
      }}
    >
      <script type="application/ld+json">{JSON.stringify(schemas)}</script>
      <link rel="preconnect" href="https://www.google.com" />
    </Helmet>
  );
};

const toFullLocale = (locale: string) => {
  switch (locale) {
    case "en":
      return "en_GB";
    case "fr":
      return "fr_FR";
    case "de":
      return "de_DE";
    case "it":
      return "it_IT";
    default:
      return locale;
  }
};
